import { createContext, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import useAuth from '../hooks/useAuth';
import { getPermission } from '../redux/slices/Permission';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  message: null,
  error: null,
  // Customer States
  company: [],
  contact: [],
  location: [],
  service: [],
  jobentery: [],
  elevator: [],
  bilal: '',
  delugeisolation: [],
  delugenameplate: [],
  delugehead: [],
  delugeguage: [],
  delugedrain: [],
  keylogs: [],
  firedep: [],
  firealarm: [],
  drysprinkler: [],
  wetsprinkler: [],
  firepump: [],
  preaction: [],
  deluge: [],
  standpipe: [],
  firehydrant: [],
  kitchenhood: [],
  drychemical: [],
  cleanagent: [],
  fireescape: [],
  fireextinguisher: [],
  backflow: [],
  otherlocation: [],
  batterydetail: [],
  ductdetector: [],
  heatdetector: [],
  supervisory: [],
  flowswitch: [],
  wetantifreeze: [],
  wetguage: [],
  wetnameplate: [],
  wetisolation: [],
  wetdrain: [],
  wethead: [],
  dryantifreeze: [],
  dryguage: [],
  drynameplate: [],
  dryisolation: [],
  drydrain: [],
  dryhead: [],
  preantifreeze: [],
  preguage: [],
  prenameplate: [],
  preisolation: [],
  predrain: [],
  prehead: [],
  spguage: [],
  spisolation: [],
  fpguage: [],
  fpisolation: [],
  khcylinderdetail: [],
  cacylinderdetail: [],
  cylinderdetail: [],
  fhdetail: [],
  fedetail: [],
  bfisolation: [],
  deficiencies: [],
  uploads:[],

};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  // Global Methoed
  LOGIN: (state, action) => {
    console.log('payload received Login->', action.payload);
    const { user, message, error } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      message,
      error
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    message: null,
    error: null,
    // Customer States
    company: null,
    contact: null,
    location: null,
    service: null,
    jobentery: null,
    elevator: null,
    keylogs: null,
    firedep: null,
    firealarm: null,
    drysprinkler: null,
    wetsprinkler: null,
    firepump: null,
    preaction: null,
    deluge: null,
    standpipe: null,
    firehydrant: null,
    kitchenhood: null,
    drychemical: null,
    cleanagent: null,
    fireescape: null,
    fireextinguisher: null,
    backflow: null,
    otherlocation: null,
    batterydetail: null,
    ductdetector: null,
    heatdetector: null,
    supervisory: null,
    flowswitch: null,
    wetantifreeze: null,
    wetguage: null,
    wetnameplate: null,
    wetisolation: null,
    wetdrain: null,
    wethead: null,
    dryantifreeze: null,
    dryguage: null,
    drynameplate: null,
    dryisolation: null,
    drydrain: null,
    dryhead: null,
    preantifreeze: null,
    preguage: null,
    prenameplate: null,
    preisolation: null,
    predrain: null,
    prehead: null,
    spguage: null,
    spisolation: null,
    fpguage: null,
    fpisolation: null,
    khcylinderdetail: null,
    cacylinderdetail: null,
    cylinderdetail: null,
    fhdetail: null,
    fedetail: null,
    bfisolation: null,
    deficiencies: null,
  }),
  REGISTER: (state, action) => {
    const { user, message, error } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      user,
      message,
      error
    };
  },
  RESETPASSWORD: (state, action) => {
    // console.log('payload received ->', action.payload);
    const { message } = action.payload;
    return {
      ...state,
      message
    };
  },
  VERIFYPASSWORD: (state, action) => {
    // console.log('payload received ->', action.payload);
    const { message } = action.payload;
    return {
      ...state,
      message
    };

  },
  // SuperAdmin Methods


  // Customer Method
  COMPANYREGISTER: (state, action) => {
    console.log('payload received ->', action.payload);
    const { company, message, error } = action.payload;
    return {
      ...state,
      company,
      message,
      error
    };
  },
  COMPANYCONTACT: (state, action) => {
    console.log('payload received ->', action.payload);
    const { contact, message, error } = action.payload;
    return {
      ...state,
      contact,
      message,
      error
    };
  },
  DOCUMENTFORM: (state, action) => {
    console.log('payload received ->', action.payload);
    const { uploads, message, error } = action.payload;
    return {
      ...state,
      uploads,
      message,
      error
    };
  },
  COMPANYLOCATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { location, message, error } = action.payload;
    return {
      ...state,
      location,
      message,
      error
    };
  },
  COMPANYSERVICE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { service, message, error } = action.payload;
    return {
      ...state,
      service,
      message,
      error
    };
  },
  COMPANYJOB: (state, action) => {
    console.log('payload received ->', action.payload);
    const { jobentery, message, error } = action.payload;
    return {
      ...state,
      jobentery,
      message,
      error
    };
  },
  COMPANYELEVATOR: (state, action) => {
    console.log('payload received ->', action.payload);
    const { elevator, message, error } = action.payload;
    return {
      ...state,
      elevator,
      message,
      error
    };
  },
  COMPANYBATTERYDETAIL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { batterydetail, message, error } = action.payload;
    return {
      ...state,
      batterydetail,
      message,
      error
    };
  },
  COMPANYDUCTDETECTOR: (state, action) => {
    console.log('payload received ->', action.payload);
    const { ductdetector, message, error } = action.payload;
    return {
      ...state,
      ductdetector,
      message,
      error
    };
  },
  COMPANYHEATDETECTOT: (state, action) => {
    console.log('payload received ->', action.payload);
    const { heatdetector, message, error } = action.payload;
    return {
      ...state,
      heatdetector,
      message,
      error
    };
  },
  COMPANYSUPERVISORY: (state, action) => {
    console.log('payload received ->', action.payload);
    const { supervisory, message, error } = action.payload;
    return {
      ...state,
      supervisory,
      message,
      error
    };
  },
  COMPANYFLOWSWITCH: (state, action) => {
    console.log('payload received ->', action.payload);
    const { flowswitch, message, error } = action.payload;
    return {
      ...state,
      flowswitch,
      message,
      error
    };
  },
  COMPANYWETANTIFREEZE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wetantifreeze, message, error } = action.payload;
    return {
      ...state,
      wetantifreeze,
      message,
      error
    };
  },

  COMPANYWETGUAGE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wetguage, message, error } = action.payload;
    return {
      ...state,
      wetguage,
      message,
      error
    };
  },
  COMPANYNWETAMEPLATE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wetnameplate, message, error } = action.payload;
    return {
      ...state,
      wetnameplate,
      message,
      error
    };
  },
  COMPANYWETISOLATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wetisolation, message, error } = action.payload;
    return {
      ...state,
      wetisolation,
      message,
      error
    };
  },
  COMPANYWETDRAIN: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wetdrain, message, error } = action.payload;
    return {
      ...state,
      wetdrain,
      message,
      error
    };
  },
  COMPANYWETHEAD: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wethead, message, error } = action.payload;
    return {
      ...state,
      wethead,
      message,
      error
    };
  },
  COMPANYDRYANTIFREEZE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { dryantifreeze, message, error } = action.payload;
    return {
      ...state,
      dryantifreeze,
      message,
      error
    };
  },
  COMPANYDRYGUAGE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { dryguage, message, error } = action.payload;
    return {
      ...state,
      dryguage,
      message,
      error
    };
  },
  COMPANYDRYNAMEPLATE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { drynameplate, message, error } = action.payload;
    return {
      ...state,
      drynameplate,
      message,
      error
    };
  },
  COMPANYDRYISOLATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { dryisolation, message, error } = action.payload;
    return {
      ...state,
      dryisolation,
      message,
      error
    };
  },
  COMPANYDRYDRAIN: (state, action) => {
    console.log('payload received ->', action.payload);
    const { drydrain, message, error } = action.payload;
    return {
      ...state,
      drydrain,
      message,
      error
    };
  },
  COMPANYDRYHEAD: (state, action) => {
    console.log('payload received ->', action.payload);
    const { dryhead, message, error } = action.payload;
    return {
      ...state,
      dryhead,
      message,
      error
    };
  },

  COMPANYPREANTIFREEZE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { preantifreeze, message, error } = action.payload;
    return {
      ...state,
      preantifreeze,
      message,
      error
    };
  },
  COMPANYPREGUAGE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { preguage, message, error } = action.payload;
    return {
      ...state,
      preguage,
      message,
      error
    };
  },
  COMPANYPRENAMEPLATE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { prenameplate, message, error } = action.payload;
    return {
      ...state,
      prenameplate,
      message,
      error
    };
  },
  COMPANYPREISOLATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { preisolation, message, error } = action.payload;
    return {
      ...state,
      preisolation,
      message,
      error
    };
  },
  COMPANYPREDRAIN: (state, action) => {
    console.log('payload received ->', action.payload);
    const { predrain, message, error } = action.payload;
    return {
      ...state,
      predrain,
      message,
      error
    };
  },
  COMPANYPREHEAD: (state, action) => {
    console.log('payload received ->', action.payload);
    const { prehead, message, error } = action.payload;
    return {
      ...state,
      prehead,
      message,
      error
    };
  },

  COMPANYKHCYLINDERDETAIL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { khcylinderdetail, message, error } = action.payload;
    return {
      ...state,
      khcylinderdetail,
      message,
      error
    };
  },
  COMPANYCYLINDERDETAIL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { cylinderdetail, message, error } = action.payload;
    return {
      ...state,
      cylinderdetail,
      message,
      error
    };
  },

  COMPANYCACYLINDERDETAIL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { cacylinderdetail, message, error } = action.payload;
    return {
      ...state,
      cacylinderdetail,
      message,
      error
    };
  },

  COMPANYFHDETAIL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { fhdetail, message, error } = action.payload;
    return {
      ...state,
      fhdetail,
      message,
      error
    };
  },
  COMPANYFEDETAIL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { fedetail, message, error } = action.payload;
    return {
      ...state,
      fedetail,
      message,
      error
    };
  },
  COMPANYSTANDGUAGE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { spguage, message, error } = action.payload;
    return {
      ...state,
      spguage,
      message,
      error
    };
  },
  COMPANYSTANDISOLATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { spisolation, message, error } = action.payload;
    return {
      ...state,
      spisolation,
      message,
      error
    };
  },
  COMPANYFPGUAGE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { fpguage, message, error } = action.payload;
    return {
      ...state,
      fpguage,
      message,
      error
    };
  },
  COMPANYFPISOLATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { fpisolation, message, error } = action.payload;
    return {
      ...state,
      fpisolation,
      message,
      error
    };
  },
  COMPANYBFISOLATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { bfisolation, message, error } = action.payload;
    return {
      ...state,
      bfisolation,
      message,
      error
    };
  },
  COMPANYKEYLOGS: (state, action) => {
    console.log('payload received ->', action.payload);
    const { keylogs, message, error } = action.payload;
    return {
      ...state,
      keylogs,
      message,
      error
    };
  },
  COMPANYFIREDEP: (state, action) => {
    console.log('payload received ->', action.payload);
    const { firedep, message, error } = action.payload;
    return {
      ...state,
      firedep,
      message,
      error
    };
  },
  COMPANYFIREALARM: (state, action) => {
    console.log('payload received ->', action.payload);
    const { firealarm, message, error } = action.payload;
    return {
      ...state,
      firealarm,
      message,
      error
    };
  },
  COMPANYDRYSPRINKLER: (state, action) => {
    console.log('payload received ->', action.payload);
    const { drysprinkler, message, error } = action.payload;
    return {
      ...state,
      drysprinkler,
      message,
      error
    };
  },
  COMPANYWETSPRINKLER: (state, action) => {
    console.log('payload received ->', action.payload);
    const { wetsprinkler, message, error } = action.payload;
    return {
      ...state,
      wetsprinkler,
      message,
      error
    };
  },
  COMPANYFIREPUMP: (state, action) => {
    console.log('payload received ->', action.payload);
    const { firepump, message, error } = action.payload;
    return {
      ...state,
      firepump,
      message,
      error
    };
  },
  COMPANYPREACTION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { preaction, message, error } = action.payload;
    return {
      ...state,
      preaction,
      message,
      error
    };
  },

  COMPANYDELUGE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { deluge, message, error } = action.payload;
    return {
      ...state,
      deluge,
      message,
      error
    };
  },

  // Turabi Sub Form Of Delugue
  DELUGEISOLATIONFORM: (state, action) => {
    console.log('payload received ->', action.payload);
    const { delugeisolation, message, error } = action.payload;
    return {
      ...state,
      delugeisolation,
      message,
      error
    };
  },

  DELEUGEHYDRAULICADD: (state, action) => {
    const { delugenameplate, message, error } = action.payload;
    return {
      ...state,
      delugenameplate,
      message,
      error
    }
  },

  DELEUGEHEAD: (state, action) => {
    const { delugehead, message, error } = action.payload;
    return {
      ...state,
      delugehead,
      message,
      error
    }
  },

  DELEUGEGUAGE: (state, action) => {
    const { delugeguage, message, error } = action.payload;
    return {
      ...state,
      delugeguage,
      message,
      error
    }
  },

  DELEUGEDRAIN: (state, action) => {
    const { delugedrain, message, error } = action.payload;
    return {
      ...state,
      delugedrain,
      message,
      error
    }
  },


  // 

  COMPANYSTANDPIPE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { standpipe, message, error } = action.payload;
    return {
      ...state,
      standpipe,
      message,
      error
    };
  },
  COMPANYFIREHYDRANT: (state, action) => {
    console.log('payload received ->', action.payload);
    const { firehydrant, message, error } = action.payload;
    return {
      ...state,
      firehydrant,
      message,
      error
    };
  },
  COMPANYKITCHENHOOD: (state, action) => {
    console.log('payload received ->', action.payload);
    const { kitchenhood, message, error } = action.payload;
    return {
      ...state,
      kitchenhood,
      message,
      error
    };
  },

  COMPANYCLEANAGENT: (state, action) => {
    console.log('payload received ->', action.payload);
    const { cleanagent, message, error } = action.payload;
    return {
      ...state,
      cleanagent,
      message,
      error
    };
  },
  COMPANYDRYCHEMICAL: (state, action) => {
    console.log('payload received ->', action.payload);
    const { drychemical, message, error } = action.payload;
    return {
      ...state,
      drychemical,
      message,
      error
    };
  },
  COMPANYFIREESCAPE: (state, action) => {
    console.log('payload received ->', action.payload);
    const { fireescape, message, error } = action.payload;
    return {
      ...state,
      fireescape,
      message,
      error
    };
  },
  COMPANYFIREEXTINGUISHER: (state, action) => {
    console.log('payload received ->', action.payload);
    const { fireextinguisher, message, error } = action.payload;
    return {
      ...state,
      fireextinguisher,
      message,
      error
    };
  },
  DEFICIENCIES: (state, action) => {
    console.log('payload received ->', action.payload);
    const { deficiencies, message, error } = action.payload;
    return {
      ...state,
      deficiencies,
      message,
      error
    };
  },
  COMPANYBACKFLOW: (state, action) => {
    console.log('payload received ->', action.payload);
    const { backflow, message, error } = action.payload;
    return {
      ...state,
      backflow,
      message,
      error
    };
  },
  COMPANYOTHERLOCATION: (state, action) => {
    console.log('payload received ->', action.payload);
    const { otherlocation, message, error } = action.payload;
    return {
      ...state,
      otherlocation,
      message,
      error
    };
  },
  COMPANYDATA: (state, action) => {
    // console.log('payload received ->', action.payload);
    const { company, contact, location, service, jobentery, elevator, keylogs, firedep, firealarm, drysprinkler, wetsprinkler, firepump, preaction, deluge, standpipe, firehydrant, kitchenhood, cleanagent, drychemical, fireescape, fireextinguisher, backflow, otherlocation, batterydetail, ductdetector, heatdetector, supervisory, flowswitch, wetantifreeze, wetguage, wetnameplate, wetisolation, wetdrain, wethead, dryantifreeze, dryguage, drynameplate, dryisolation, drydrain, dryhead, delugeisolation,
      delugenameplate, delugedrain, preantifreeze, preguage, prenameplate, preisolation, predrain, prehead, spguage, spisolation, fpguage, fpisolation, khcylinderdetail, cacylinderdetail, cylinderdetail, fhdetail, fedetail, bfisolation, delugehead, delugeguage } = action.payload;
    return {
      ...state,
      company,
      contact,
      location,
      service,
      jobentery,
      elevator,
      keylogs,
      firedep,
      firealarm,
      drysprinkler,
      wetsprinkler,
      firepump,
      preaction,
      deluge,
      standpipe,
      firehydrant,
      kitchenhood,
      cleanagent,
      drychemical,
      fireescape,
      fireextinguisher,
      backflow,
      otherlocation,
      batterydetail,
      ductdetector,
      heatdetector,
      supervisory,
      flowswitch,
      wetantifreeze,
      wetguage,
      wetnameplate,
      wetisolation,
      wetdrain,
      wethead,
      dryantifreeze,
      dryguage,
      drynameplate,
      dryisolation,
      drydrain,
      dryhead,
      delugeisolation,
      delugenameplate,
      delugedrain,
      preantifreeze,
      preguage,
      prenameplate,
      preisolation,
      predrain,
      prehead,
      spguage,
      spisolation,
      fpguage,
      fpisolation,
      khcylinderdetail,
      cacylinderdetail,
      cylinderdetail,
      fhdetail,
      fedetail,
      bfisolation,
      delugehead,
      delugeguage
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  // Global Api
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  ChangePath: () => Promise.resolve(),
  resetpassword: () => Promise.resolve(),
  verifypassword: () => Promise.resolve(),
  // Super Admin Api

  // Coustomer Api
  companyregister: () => Promise.resolve(),
  companyupdate: () => Promise.resolve(),
  customercompany: () => Promise.resolve(),
  companycontact: () => Promise.resolve(),
  companycontactupdate: () => Promise.resolve(),
  companylocation: () => Promise.resolve(),
  companylocationupdate: () => Promise.resolve(),
  companyservice: () => Promise.resolve(),
  companyserviceupdate: () => Promise.resolve(),
  companyjob: () => Promise.resolve(),
  companyjobupdate: () => Promise.resolve(),
  companyelevator: () => Promise.resolve(),
  companyelevatorupdate: () => Promise.resolve(),
  companykeylogs: () => Promise.resolve(),
  updatecompanykeylogs: () => Promise.resolve(),
  companyfiredep: () => Promise.resolve(),
  updatecompanyfiredep: () => Promise.resolve(),
  firealarmregister: () => Promise.resolve(),
  updatefirealarmregister: () => Promise.resolve(),
  drysprinklerregister: () => Promise.resolve(),
  updatedrysprinklerregister: () => Promise.resolve(),
  wetsprinklerregister: () => Promise.resolve(),
  updatewetsprinklerregister: () => Promise.resolve(),
  firepumpregister: () => Promise.resolve(),
  updatefirepumpregister: () => Promise.resolve(),
  preaction: () => Promise.resolve(),
  delugeregister: () => Promise.resolve(),
  updatedelugeregister: () => Promise.resolve(),
  standpiperegister: () => Promise.resolve(),
  updatestandpiperegister: () => Promise.resolve(),
  firehydrantregister: () => Promise.resolve(),
  updatefirehydrantregister: () => Promise.resolve(),
  kitchenhoodregister: () => Promise.resolve(),
  updatekitchenhoodregister: () => Promise.resolve(),
  cleanagentregister: () => Promise.resolve(),
  updatecleanagentregister: () => Promise.resolve(),
  drychemicalregister: () => Promise.resolve(),
  updatedrychemicalregister: () => Promise.resolve(),
  fireescape: () => Promise.resolve(),
  fireextinguisher: () => Promise.resolve(),
  backflow: () => Promise.resolve(),
  otherlocation: () => Promise.resolve(),
  companybatterydetail: () => Promise.resolve(),
  companyupdatebatterydetail: () => Promise.resolve(),
  companyductdetector: () => Promise.resolve(),
  companyupdateductdetector: () => Promise.resolve(),
  companyheatdetector: () => Promise.resolve(),
  companyupdateheatdetector: () => Promise.resolve(),
  companyflowswitch: () => Promise.resolve(),
  companyupdateflowswitch: () => Promise.resolve(),
  companysupervisory: () => Promise.resolve(),
  companyupdatesupervisory: () => Promise.resolve(),
  companywetantifreeze: () => Promise.resolve(),
  companyupdatewetantifreeze: () => Promise.resolve(),
  companywetguage: () => Promise.resolve(),
  companyupdatewetguage: () => Promise.resolve(),
  companywetnameplate: () => Promise.resolve(),
  companyupdatewetnameplate: () => Promise.resolve(),
  companywetisolation: () => Promise.resolve(),
  companyupdatewetisolation: () => Promise.resolve(),
  companywetdrain: () => Promise.resolve(),
  companyupdatewetdrain: () => Promise.resolve(),
  companywethead: () => Promise.resolve(),
  companyupdatewethead: () => Promise.resolve(),
  companydryantifreeze: () => Promise.resolve(),
  companydryguage: () => Promise.resolve(),
  companyupdatedryguage: () => Promise.resolve(),
  companydrynameplate: () => Promise.resolve(),
  companyupdatedrynameplate: () => Promise.resolve(),
  companydryisolation: () => Promise.resolve(),
  companyupdatedryisolation: () => Promise.resolve(),
  companydrydrain: () => Promise.resolve(),
  companyupdatedrydrain: () => Promise.resolve(),
  companydryhead: () => Promise.resolve(),
  companyupdatedryhead: () => Promise.resolve(),
  // Turabi
  delugeisolationadd: () => Promise.resolve(),
  delugeisolationupdate: () => Promise.resolve(),
  deleugehydraulicadd: () => Promise.resolve(),
  deleugehydraulicupdate: () => Promise.resolve(),
  delugeheadadd: () => Promise.resolve(),
  delugeheadupdate: () => Promise.resolve(),
  delugeguageadd: () => Promise.resolve(),
  delugeguageupdate: () => Promise.resolve(),
  delugedrainadd: () => Promise.resolve(),
  delugedrainupdate: () => Promise.resolve(),
  companypreantifreeze: () => Promise.resolve(),
  companypreguage: () => Promise.resolve(),
  companyupdatepreguage: () => Promise.resolve(),
  companyprenameplate: () => Promise.resolve(),
  companyupdateprenameplate: () => Promise.resolve(),
  companypreisolation: () => Promise.resolve(),
  companyupdatepreisolation: () => Promise.resolve(),
  companypredrain: () => Promise.resolve(),
  companyupdatepredrain: () => Promise.resolve(),
  companyprehead: () => Promise.resolve(),
  companyupdateprehead: () => Promise.resolve(),
  companyspguage: () => Promise.resolve(),
  companyupdatespguage: () => Promise.resolve(),
  companyspisolation: () => Promise.resolve(),
  companyupdatespisolation: () => Promise.resolve(),
  companyfpguage: () => Promise.resolve(),
  companyupdatefpguage: () => Promise.resolve(),
  companyfpisolation: () => Promise.resolve(),
  companyupdatefpisolation: () => Promise.resolve(),
  companykhcylinderdetail: () => Promise.resolve(),
  companyupdatekhcylinderdetail: () => Promise.resolve(),
  companycylinderdetail: () => Promise.resolve(),
  companyupdatecylinderdetail: () => Promise.resolve(),
  companycacylinderdetail: () => Promise.resolve(),
  companyupdatecacylinderdetail: () => Promise.resolve(),
  companyfhdetail: () => Promise.resolve(),
  companyupdatefhdetail: () => Promise.resolve(),
  companyfedetail: () => Promise.resolve(),
  companyupdatefedetail: () => Promise.resolve(),
  companybfisolation: () => Promise.resolve(),
  companyupdatebfisolation: () => Promise.resolve(),
  GetDocumentData: () => Promise.resolve(),
  getalldefencie: () => Promise.resolve(),
  preactionregister: () => Promise.resolve(),
  updatepreaction: () => Promise.resolve(),
  fireextinguisherregister: () => Promise.resolve(),
  updatefireextinguisher: () => Promise.resolve(),
  backflowregister: () => Promise.resolve(),
  updatebackflowregister: () => Promise.resolve(),
  fireescaperegister: () => Promise.resolve(),
  updatefireescaperegister: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pathname, setPathName] = useState('')
  const ChangePath = () => {
    setPathName(window.location.pathname)
  }
  const UserIDs = localStorage.getItem('userID');
  useEffect(() => { ChangePath() }, [window.location.pathname])

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          customercompany(UserIDs)
          const response = await axios.get(`api/data`);
          const { user } = response.data;
          getPermission(user.id)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,

            },
          });
        }
      } catch (err) {
        console.error('error -> ', err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {id:1},
          },
        });

      }
    };

    initialize();
  }, []);

  // Global Apis
  const login = async (email, password) => {
    // console.log(pathname)
    if (pathname === '/superadmin/login') {
      // console.log('i Am SuperAdmin')
      const response = await axios.post('/api/superadmin/login', {
        email,
        password,
      });
      const { accessToken, user, userdetail, status, error } = response.data;

      if (status === 'success') {
        // console.log('data==>', accessToken, user, status)
        setSession(accessToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            error
          },
        });
      }
    }
    
    if (pathname === '/subadmin/login') {
      // console.log('i Am Admin')
      const response = await axios.post('/api/subadmin/login', {
        email,
        password,
      });
      const { accessToken, user, status, error,userdetail } = response.data;

      if (status === 'success') {
        // console.log('data==>', accessToken, user, status)
        setSession(accessToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            error
          },
        });
    getPermission(userdetail.id)

      }
    }
    if (pathname === '/customer/login') {
      // console.log('i Am Customer')
      const response = await axios.post('/api/customer/login', {
        email,
        password,
      });
      const { accessToken, user, status, error, message } = response.data;
      console.log(' response.data', response.data);
      // console.log('user', user);
      if (status === 'success') {
        localStorage.setItem('userID', user.id);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('userRole', user.role);
        setSession(accessToken);
        customercompany(user.id);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            message,
            error
          },
        });
      }
      if (status === 'failed') {
        dispatch({
          type: 'LOGIN',
          payload: {
            message,
            error
          },
        });
      }
    }
    if (pathname === '/contractor/login') {
      // console.log('i Am contractor')
      const response = await axios.post('/api/contractor/login', {
        email,
        password,
      });
      const { accessToken, user, status, message, error } = response.data;

      console.log('user', user);
      if (status === 'success') {
        localStorage.setItem('userID', user.id);
        setSession(accessToken);
        // customercompany(user.id);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            message,
            error
          },
        });
      }
      if (status === 'failed') {
        dispatch({
          type: 'LOGIN',
          payload: {
            message,
            error
          },
        });
      }
    }
    if (pathname === '/technician/login') {
      // console.log('i Am technician')
      const response = await axios.post('/api/technician/login', {
        email,
        password,
      });
      const { accessToken, user, status, error } = response.data;
      if (status === 'success') {
        // console.log(' response.data', response.data);
        // console.log('data==>', accessToken, user, status)
        setSession(accessToken);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            error
          },
        });
      }
    }

  };

  const [ contractorread, setContractorRead] = useState('')

    // const Permission = async () => {
    //     const response = await axios.get(`api/subadmin/allow/permission/${UserIDs}`)
    //     const {Permissiondetail} = response.data
    //     console.log('premission access', Permission)
    //     setContractorRead(Permissiondetail);
    // }

  const register = async (email, password, firstname, lastname) => {
    if (pathname === '/contractor/register') {
      const response = await axios.post('/api/contractor/register', {
        email,
        password,
        firstname,
        lastname,
      });
      const { user, message, error } = response.data;
      // console.log(' response.data', response.data);  
      // window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
          message,
          error
        },
      });
    }
    if (pathname === '/customer/register') {
      const response = await axios.post('/api/customer/register', {
        email,
        password,
        firstname,
        lastname,
      });
      const { user, message, error } = response.data;
      // console.log(' response.data', response.data);  
      // window.localStorage.setItem('accessToken', accessToken);
      dispatch({
        type: 'REGISTER',
        payload: {
          user,
          message,
          error
        },
      });
    }

  };

  const logout = async () => {
    return new Promise((resolve,reject) => {
      setSession(null);
      localStorage.clear();
      dispatch({ type: 'LOGOUT' });
      resolve({ status: true })
    })
  };

  const resetpassword = async (email) => {
    if (pathname === '/customer/reset-password') {
      const response = await axios.post(`api/customer/forgotpassword`, { email });
      const { message, error } = response.data;
      dispatch({
        type: 'RESETPASSWORD',
        payload: {
          message,
          error
        },
      });
    };
    if (pathname === '/contractor/reset-password') {
      const response = await axios.post(`api/contractor/forgotpassword`, { email });
      const { message, error } = response.data;
      dispatch({
        type: 'RESETPASSWORD',
        payload: {
          message,
          error
        },
      });
    };
  }

  const verifypassword = async (token, password, confirmpassword) => {
    if (pathname === '/customer/verify') {
      const response = await axios.post(`api/customer/resetpassword`, { token, password, confirmpassword });
      const { message, error } = response.data;
      dispatch({
        type: 'VERIFYPASSWORD',
        payload: {
          message,
          error
        },
      });
    }
    if (pathname === '/contractor/verify') {
      const response = await axios.post(`api/contractor/resetpassword`, { token, password, confirmpassword });
      const { message, error } = response.data;
      dispatch({
        type: 'VERIFYPASSWORD',
        payload: {
          message,
          error
        },
      });
    }
  }

  // Super Admin Api

  // Customer APIS
  const companyregister = async (customerid, companyname, streetaddress, city, state, suitenumber, zipcode, website, email, phone, fax, notes, logo) => {
    console.log(logo);
    
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("companyname", companyname)
    formData.append("streetaddress", streetaddress)
    formData.append("city", city)
    formData.append("state", state)
    formData.append("suitenumber", suitenumber)
    formData.append("zipcode", zipcode)
    formData.append("website", website)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("fax", fax)
    formData.append("notes", notes)
    formData.append("logo", logo)

    // return;

    const response = await axios.post('api/customer/addcompany', formData);
    // console.log('response after api hit ->', response.data)
    const { company, error, message } = response.data;
    localStorage.setItem('companyID', company.id);
    dispatch({
      type: 'COMPANYREGISTER',
      payload: {
        company,
        message,
        error
      },
    });
  };

  const companyupdate = async (id, customerid, companyname, streetaddress, city, state, suitenumber, zipcode, website, email, phone, fax, notes, logo) => {
    console.log(logo);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("companyname", companyname)
    formData.append("streetaddress", streetaddress)
    formData.append("city", city)
    formData.append("state", state)
    formData.append("suitenumber", suitenumber)
    formData.append("zipcode", zipcode)
    formData.append("website", website)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("fax", fax)
    formData.append("notes", notes)
    formData.append("logo", logo)
    // return;
    const response = await axios.post(`api/customer/updatecompany/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { company, error, message } = response.data;
    dispatch({
      type: 'COMPANYREGISTER',
      payload: {
        company,
        message,
        error
      },
    });
  };

  const companycontact = async (customerid, firstname, lastname, email, phonecell, officephone, role, description, notes) => {
    const response = await axios.post('api/customer/adduserinfo', { customerid, firstname, lastname, email, phonecell, officephone, role, description, notes });
    const { contact, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('contactID', contact.id);
    dispatch({
      type: 'COMPANYCONTACT',
      payload: {
        contact,
        message,
        error
      },
    });
  };
  const companycontactupdate = async (id, customerid, firstname, lastname, email, phonecell, officephone, role, description, notes) => {
    const response = await axios.post(`api/customer/updateuserinfo/${id}`, { customerid, firstname, lastname, email, phonecell, officephone, role, description, notes });
    const { contact, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('contactID', contact.id);
    dispatch({
      type: 'COMPANYCONTACT',
      payload: {
        contact,
        message,
        error
      },
    });
  };
  const companylocation = async (customerid, name, address, city, country, state, zip, floors, floorscount, elevator, elevatorcount, resdentialunit, resdentialunitcount, firedepartmentconnection, accessnotes, locationkeyquantity, locationnotes, image,
    firealarmcount,
    wetsprinklercount,
    drysprinklercount,
    preactioncount,
    delugecount,
    standpipecount,
    firepumpcount,
    firehydrantcount,
    kitchenhoodcount,
    drychemicalcount,
    cleanagentcount,
    fireescapecount,
    fireextingushercount,
    backflowcount,
    othercount) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("name", name)
    formData.append("address", address)
    formData.append("city", city)
    formData.append("country", country)
    formData.append("state", state)
    formData.append("zip", zip)
    formData.append("floors", floors)
    formData.append("floorscount", floorscount)
    formData.append("elevator", elevator)
    formData.append("elevatorcount", elevatorcount)
    formData.append("resdentialunit", resdentialunit)
    formData.append("resdentialunitcount", resdentialunitcount)
    formData.append("firedepartmentconnection", firedepartmentconnection)
    formData.append("accessnotes", accessnotes)
    formData.append("locationkeyquantity", locationkeyquantity)
    formData.append("locationnotes", locationnotes)
    formData.append("image", image)
    formData.append("firealarmcount",firealarmcount)
    formData.append("wetsprinklercount",wetsprinklercount)
    formData.append("drysprinklercount",drysprinklercount)
    formData.append("preactioncount",preactioncount)
    formData.append("delugecount",delugecount)
    formData.append("standpipecount",standpipecount)
    formData.append("firepumpcount",firepumpcount)
    formData.append("firehydrantcount",firehydrantcount)
    formData.append("kitchenhoodcount",kitchenhoodcount)
    formData.append("drychemicalcount",drychemicalcount)
    formData.append("cleanagentcount",cleanagentcount)
    formData.append("fireescapecount",fireescapecount)
    formData.append("fireextingushercount",fireextingushercount)
    formData.append("backflowcount",backflowcount)
    formData.append("othercount",othercount)

    const response = await axios.post('api/customer/addcompanylocation', formData);
    // console.log('response after api hit ->', response.data)
    const { location, error, message } = response.data;
    localStorage.setItem('locationID', location.id);
    dispatch({
      type: 'COMPANYLOCATION',
      payload: {
        location,
        message,
        error
      },
    });
  };
  const companylocationupdate = async (id, customerid, name, address, city, country, state, zip, floors, floorscount, elevator, elevatorcount, resdentialunit, resdentialunitcount, firedepartmentconnection, accessnotes, locationkeyquantity, locationnotes, image,
    firealarmcount,
    wetsprinklercount,
    drysprinklercount,
    preactioncount,
    delugecount,
    standpipecount,
    firepumpcount,
    firehydrantcount,
    kitchenhoodcount,
    drychemicalcount,
    cleanagentcount,
    fireescapecount,
    fireextingushercount,
    backflowcount,
    othercount) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("name", name)
    formData.append("address", address)
    formData.append("city", city)
    formData.append("country", country)
    formData.append("state", state)
    formData.append("zip", zip)
    formData.append("floors", floors)
    formData.append("floorscount", floorscount)
    formData.append("elevator", elevator)
    formData.append("elevatorcount", elevatorcount)
    formData.append("resdentialunit", resdentialunit)
    formData.append("resdentialunitcount", resdentialunitcount)
    formData.append("firedepartmentconnection", firedepartmentconnection)
    formData.append("accessnotes", accessnotes)
    formData.append("locationkeyquantity", locationkeyquantity)
    formData.append("locationnotes", locationnotes)
    formData.append("image", image)
    formData.append("firealarmcount",firealarmcount)
    formData.append("wetsprinklercount",wetsprinklercount)
    formData.append("drysprinklercount",drysprinklercount)
    formData.append("preactioncount",preactioncount)
    formData.append("delugecount",delugecount)
    formData.append("standpipecount",standpipecount)
    formData.append("firepumpcount",firepumpcount)
    formData.append("firehydrantcount",firehydrantcount)
    formData.append("kitchenhoodcount",kitchenhoodcount)
    formData.append("drychemicalcount",drychemicalcount)
    formData.append("cleanagentcount",cleanagentcount)
    formData.append("fireescapecount",fireescapecount)
    formData.append("fireextingushercount",fireextingushercount)
    formData.append("backflowcount",backflowcount)
    formData.append("othercount",othercount)

    const response = await axios.post(`api/customer/updatecompanylocation/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { location, error, message } = response.data;
    localStorage.setItem('locationID', location.id);
    dispatch({
      type: 'COMPANYLOCATION',
      payload: {
        location,
        message,
        error
      },
    });
  };
  const companyservice = async (customerid, assettype, servicetype, servicefrequency, servicedescription, servicewindow, servicedate, nextscheduledservicedate) => {
    const response = await axios.post('api/customer/addserviceentry', {
      customerid,
      assettype,
      servicetype,
      servicefrequency,
      servicedescription,
      servicewindow,
      servicedate,
      nextscheduledservicedate
    });
    const { service, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('serviceID', service.id);
    dispatch({
      type: 'COMPANYSERVICE',
      payload: {
        service,
        message,
        error
      },
    });
  };
  const companyserviceupdate = async (customerid, assettype, servicetype, servicefrequency, servicedescription, servicewindow, servicedate, nextscheduledservicedate) => {
    const response = await axios.post(`api/customer/updateserviceentry/${UserIDs}`, {
      customerid,
      assettype,
      servicetype,
      servicefrequency,
      servicedescription,
      servicewindow,
      servicedate,
      nextscheduledservicedate
    });
    const { service, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('serviceID', service.id);
    dispatch({
      type: 'COMPANYSERVICE',
      payload: {
        service,
        message,
        error
      },
    });
  };
  const companyjob = async (customerid, locationname, locationservices, jobtype, jobdescription, jobscheduleddate, jobscheduledstarttime, startingwindow, contractorcompany, scheduledtechnician, technicianphone, clockintime, techniciancountonsite, partsused, distancefromhomeoffice, anyissues, servicelistcomplete, clockouttime) => {
    const response = await axios.post('api/customer/addjobentry', {
      customerid,
      locationname,
      locationservices,
      jobtype,
      jobdescription,
      jobscheduleddate,
      jobscheduledstarttime,
      startingwindow,
      contractorcompany,
      scheduledtechnician,
      technicianphone,
      clockintime,
      techniciancountonsite,
      partsused,
      distancefromhomeoffice,
      anyissues,
      servicelistcomplete,
      clockouttime,
    });
    const { jobentery, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('jobenteryID', jobentery.id);
    dispatch({
      type: 'COMPANYJOB',
      payload: {
        jobentery,
        message,
        error
      },
    });
  };
  const companyjobupdate = async (customerid, locationname, locationservices, jobtype, jobdescription, jobscheduleddate, jobscheduledstarttime, startingwindow, contractorcompany, scheduledtechnician, technicianphone, clockintime, techniciancountonsite, partsused, distancefromhomeoffice, anyissues, servicelistcomplete, clockouttime) => {
    const response = await axios.post(`api/customer/updatejobentry/${UserIDs}`, {
      customerid,
      locationname,
      locationservices,
      jobtype,
      jobdescription,
      jobscheduleddate,
      jobscheduledstarttime,
      startingwindow,
      contractorcompany,
      scheduledtechnician,
      technicianphone,
      clockintime,
      techniciancountonsite,
      partsused,
      distancefromhomeoffice,
      anyissues,
      servicelistcomplete,
      clockouttime,
    });
    const { jobentery, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('jobenteryID', jobentery.id);
    dispatch({
      type: 'COMPANYJOB',
      payload: {
        jobentery,
        message,
        error
      },
    });
  };
  const companyelevator = async (customerid, locationname, elevatornumber, elevatorlocation, assetmodel, assetserialnumber, assetbornondate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, floorserviced, primaryrecallfloor, secondaryrecallfloor, schematicprovided, firerecall, servicedate, nextscheduled) => {
    const response = await axios.post('api/customer/addelevatordetail', {
      customerid,
      locationname,
      elevatornumber,
      elevatorlocation,
      assetmodel,
      assetserialnumber,
      assetbornondate,
      installingcontractor,
      servicecontractor,
      distributorcompany,
      assetahj,
      assetcondition,
      floorserviced,
      primaryrecallfloor,
      secondaryrecallfloor,
      schematicprovided,
      firerecall,
      servicedate,
      nextscheduled
    });
    const { elevator, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('elevatorID', elevator.id);
    dispatch({
      type: 'COMPANYELEVATOR',
      payload: {
        elevator,
        message,
        error
      },
    });
  };
  const companyelevatorupdate = async (id, customerid, locationname, elevatornumber, elevatorlocation, assetmodel, assetserialnumber, assetbornondate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, floorserviced, primaryrecallfloor, secondaryrecallfloor, schematicprovided, firerecall, servicedate, nextscheduled) => {
    const response = await axios.post(`api/customer/updateelevatordetail/${id}`, {
      customerid,
      locationname,
      elevatornumber,
      elevatorlocation,
      assetmodel,
      assetserialnumber,
      assetbornondate,
      installingcontractor,
      servicecontractor,
      distributorcompany,
      assetahj,
      assetcondition,
      floorserviced,
      primaryrecallfloor,
      secondaryrecallfloor,
      schematicprovided,
      firerecall,
      servicedate,
      nextscheduled
    });
    const { elevator, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('elevatorID', elevator.id);
    dispatch({
      type: 'COMPANYELEVATOR',
      payload: {
        elevator,
        message,
        error
      },
    });
  };
  const companykeylogs = async (customerid, keyidnumber, locationname, keytype, OFPMhavecopy, onsitelocation, onsitelockboxcode, manufacturer, stampcode, notes) => {
    const response = await axios.post('api/customer/addkeylog', { customerid, keyidnumber, locationname, keytype, OFPMhavecopy, onsitelocation, onsitelockboxcode, manufacturer, stampcode, notes });
    const { keylogs, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('keylogsID', keylogs.id);
    dispatch({
      type: 'COMPANYKEYLOGS',
      payload: {
        keylogs,
        message,
        error
      },
    });
  };
  const updatecompanykeylogs = async (id, customerid, keyidnumber, locationname, keytype, OFPMhavecopy, onsitelocation, onsitelockboxcode, manufacturer, stampcode, notes) => {
    const response = await axios.post(`api/customer/updatekeylog/${id}`, { customerid, keyidnumber, locationname, keytype, OFPMhavecopy, onsitelocation, onsitelockboxcode, manufacturer, stampcode, notes });
    const { keylogs, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('keylogsID', keylogs.id);
    dispatch({
      type: 'COMPANYKEYLOGS',
      payload: {
        keylogs,
        message,
        error
      },
    });
  };
  const companyfiredep = async (customerid, fdcshared, fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, servicedate, nextscheduled) => {
    const response = await axios.post('api/customer/addconnectiondetail', { customerid, fdcshared, fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, servicedate, nextscheduled });
    const { firedep, error, message } = response.data;
    // // console.log('response after api hit ->',)
    // localStorage.setItem('firedepID', firedep.id);
    dispatch({
      type: 'COMPANYFIREDEP',
      payload: {
        firedep,
        message,
        error
      },
    });
  };
  const updatecompanyfiredep = async (id, customerid, fdcshared, fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, servicedate, nextscheduled) => {
    const response = await axios.post(`api/customer/updateconnectiondetail/${id}`, { customerid, fdcshared, fdcsharesaddress, fdcrisers, fdclocation, fdcsize, connectiontype, valveconnection, valvetype, servicedate, nextscheduled });
    const { firedep, error, message } = response.data;
    // // console.log('response after api hit ->',)
    // localStorage.setItem('firedepID', firedep.id);
    dispatch({
      type: 'COMPANYFIREDEP',
      payload: {
        firedep,
        message,
        error
      },
    });
  };

  const firealarmregister = async (customerid, assetid, photo, placardphoto, assetname, location, protect, make, model, serial, bron, installingcontractor, servicecontractor, distributorcompany, ahj, condition, mointeringcompany, panelmoniterd, replacepart, buget, keyavailble, keylog, backupavalable, backuplocation, researchtask, quantitybatteries, paneltype, circuitstyle, numberinitiating, numbersingnaling, subpanel, pullstation, smokedetector, numsmokedetector, ductdetector, heatdetector, flowswitch, supervioryswitches, bell, chimes, horn, strobes, hornstrobes, contractorid, serviesdate, nextscheduled, note) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("photo", photo)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serial", serial)
    formData.append("bron", bron)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("ahj", ahj)
    formData.append("condition", condition)
    formData.append("mointeringcompany", mointeringcompany)
    formData.append("panelmoniterd", panelmoniterd)
    formData.append("replacepart", replacepart)
    formData.append("buget", buget)
    formData.append("keyavailble", keyavailble)
    formData.append("keylog", keylog)
    formData.append("backupavalable", backupavalable)
    formData.append("backuplocation", backuplocation)
    formData.append("researchtask", researchtask)
    formData.append("quantitybatteries", quantitybatteries)
    formData.append("paneltype", paneltype)
    formData.append("circuitstyle", circuitstyle)
    formData.append("numberinitiating", numberinitiating)
    formData.append("numbersingnaling", numbersingnaling)
    formData.append("subpanel", subpanel)
    formData.append("pullstation", pullstation)
    formData.append("smokedetector", smokedetector)
    formData.append("numsmokedetector", numsmokedetector)
    formData.append("ductdetector", ductdetector)
    formData.append("heatdetector", heatdetector)
    formData.append("flowswitch", flowswitch)
    formData.append("supervioryswitches", supervioryswitches)
    formData.append("bell", bell)
    formData.append("chimes", chimes)
    formData.append("horn", horn)
    formData.append("strobes", strobes)
    formData.append("hornstrobes", hornstrobes)
    formData.append("contractorid", contractorid)
    formData.append("serviesdate", serviesdate)
    formData.append("nextscheduled", nextscheduled)
    formData.append("note", note)

    const response = await axios.post('api/customer/firealarm', formData);
    // console.log('response after api hit ->', response.data)
    const { firealarm, error, message } = response.data;
    localStorage.setItem('firealarmID', firealarm.id);
    dispatch({
      type: 'COMPANYFIREALARM',
      payload: {
        firealarm,
        message,
        error
      },
    });
  };

  const updatefirealarmregister = async (id, customerid, assetid, photo, placardphoto, assetname, location, protect, make, model, serial, bron, installingcontractor, servicecontractor, distributorcompany, ahj, condition, mointeringcompany, panelmoniterd, replacepart, buget, keyavailble, keylog, backupavalable, backuplocation, researchtask, quantitybatteries, paneltype, circuitstyle, numberinitiating, numbersingnaling, subpanel, pullstation, smokedetector, numsmokedetector, ductdetector, heatdetector, flowswitch, supervioryswitches, bell, chimes, horn, strobes, hornstrobes, contractorid, serviesdate, nextscheduled, note) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("photo", photo)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serial", serial)
    formData.append("bron", bron)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("ahj", ahj)
    formData.append("condition", condition)
    formData.append("mointeringcompany", mointeringcompany)
    formData.append("panelmoniterd", panelmoniterd)
    formData.append("replacepart", replacepart)
    formData.append("buget", buget)
    formData.append("keyavailble", keyavailble)
    formData.append("keylog", keylog)
    formData.append("backupavalable", backupavalable)
    formData.append("backuplocation", backuplocation)
    formData.append("researchtask", researchtask)
    formData.append("quantitybatteries", quantitybatteries)
    formData.append("paneltype", paneltype)
    formData.append("circuitstyle", circuitstyle)
    formData.append("numberinitiating", numberinitiating)
    formData.append("numbersingnaling", numbersingnaling)
    formData.append("subpanel", subpanel)
    formData.append("pullstation", pullstation)
    formData.append("smokedetector", smokedetector)
    formData.append("numsmokedetector", numsmokedetector)
    formData.append("ductdetector", ductdetector)
    formData.append("heatdetector", heatdetector)
    formData.append("flowswitch", flowswitch)
    formData.append("supervioryswitches", supervioryswitches)
    formData.append("bell", bell)
    formData.append("chimes", chimes)
    formData.append("horn", horn)
    formData.append("strobes", strobes)
    formData.append("hornstrobes", hornstrobes)
    formData.append("contractorid", contractorid)
    formData.append("serviesdate", serviesdate)
    formData.append("nextscheduled", nextscheduled)
    formData.append("note", note)

    const response = await axios.post(`api/customer/updatefirealarm/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { firealarm, error, message } = response.data;
    localStorage.setItem('firealarmID', firealarm.id);
    dispatch({
      type: 'COMPANYFIREALARM',
      payload: {
        firealarm,
        message,
        error
      },
    });
  };

  const drysprinklerregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementparts, budgetqouterequest, hydraulicallydesigned, nexttripdue, aircompressorid, aircompressormake, aircompressormodel, aircompressorcapacity, aircompressorhorsepower, aircompressorpowersupply, aircompressortank, maintenancedevice, maintenancedevicemake, maintenancedevicemodel, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementparts", replacementparts)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("hydraulicallydesigned", hydraulicallydesigned)
    formData.append("nexttripdue", nexttripdue)
    formData.append("aircompressorid", aircompressorid)
    formData.append("aircompressormake", aircompressormake)
    formData.append("aircompressormodel", aircompressormodel)
    formData.append("aircompressorcapacity", aircompressorcapacity)
    formData.append("aircompressorhorsepower", aircompressorhorsepower)
    formData.append("aircompressorpowersupply", aircompressorpowersupply)
    formData.append("aircompressortank", aircompressortank)
    formData.append("maintenancedevice", maintenancedevice)
    formData.append("maintenancedevicemake", maintenancedevicemake)
    formData.append("maintenancedevicemodel", maintenancedevicemodel)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/adddrysprinkler', formData);
    // console.log('response after api hit ->', response.data)
    const { drysprinkler, error, message } = response.data;
    localStorage.setItem('drysprinklerID', drysprinkler.id);
    dispatch({
      type: 'COMPANYDRYSPRINKLER',
      payload: {
        drysprinkler,
        message,
        error
      },
    });
  };
  const updatedrysprinklerregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementparts, budgetqouterequest, hydraulicallydesigned, nexttripdue, aircompressorid, aircompressormake, aircompressormodel, aircompressorcapacity, aircompressorhorsepower, aircompressorpowersupply, aircompressortank, maintenancedevice, maintenancedevicemake, maintenancedevicemodel, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementparts", replacementparts)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("hydraulicallydesigned", hydraulicallydesigned)
    formData.append("nexttripdue", nexttripdue)
    formData.append("aircompressorid", aircompressorid)
    formData.append("aircompressormake", aircompressormake)
    formData.append("aircompressormodel", aircompressormodel)
    formData.append("aircompressorcapacity", aircompressorcapacity)
    formData.append("aircompressorhorsepower", aircompressorhorsepower)
    formData.append("aircompressorpowersupply", aircompressorpowersupply)
    formData.append("aircompressortank", aircompressortank)
    formData.append("maintenancedevice", maintenancedevice)
    formData.append("maintenancedevicemake", maintenancedevicemake)
    formData.append("maintenancedevicemodel", maintenancedevicemodel)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatedrysprinkler/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { drysprinkler, error, message } = response.data;
    localStorage.setItem('drysprinklerID', drysprinkler.id);
    dispatch({
      type: 'COMPANYDRYSPRINKLER',
      payload: {
        drysprinkler,
        message,
        error
      },
    });
  };

  const wetsprinklerregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementavailable, budgetqouterequest, systemhydraulicallydesigned, antfreezeconnection, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextdate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("systemhydraulicallydesigned", systemhydraulicallydesigned)
    formData.append("antfreezeconnection", antfreezeconnection)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextdate", nextdate)

    const response = await axios.post('api/customer/addwetsprinkler', formData);
    // console.log('response after api hit ->', response.data)
    const { wetsprinkler, error, message } = response.data;
    localStorage.setItem('wetsprinklerID', wetsprinkler.id);
    dispatch({
      type: 'COMPANYWETSPRINKLER',
      payload: {
        wetsprinkler,
        message,
        error
      },
    });
  };
  const updatewetsprinklerregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementavailable, budgetqouterequest, systemhydraulicallydesigned, antfreezeconnection, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextdate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("systemhydraulicallydesigned", systemhydraulicallydesigned)
    formData.append("antfreezeconnection", antfreezeconnection)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextdate", nextdate)

    const response = await axios.post(`api/customer/updatewetsprinkler/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { wetsprinkler, error, message } = response.data;
    localStorage.setItem('wetsprinklerID', wetsprinkler.id);
    dispatch({
      type: 'COMPANYWETSPRINKLER',
      payload: {
        wetsprinkler,
        message,
        error
      },
    });
  };
  const backflowregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, backflowtype, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, replacementavailable, budgetqouterequest, backflowaboveground, chanceforflooding, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("backflowtype", backflowtype)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("backflowaboveground", backflowaboveground)
    formData.append("chanceforflooding", chanceforflooding)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addbackflow', formData);
    // console.log('response after api hit ->', response.data)
    const { backflow, error, message } = response.data;
    localStorage.setItem('backflowID', backflow.id);
    dispatch({
      type: 'COMPANYBACKFLOW',
      payload: {
        backflow,
        message,
        error
      },
    });
  };
  const updatebackflowregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, backflowtype, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, replacementavailable, budgetqouterequest, backflowaboveground, chanceforflooding, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("backflowtype", backflowtype)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("backflowaboveground", backflowaboveground)
    formData.append("chanceforflooding", chanceforflooding)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatebackflow/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { backflow, error, message } = response.data;
    localStorage.setItem('backflowID', backflow.id);
    dispatch({
      type: 'COMPANYBACKFLOW',
      payload: {
        backflow,
        message,
        error
      },
    });
  };

  const firepumpregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, pumptype, replacementavailable, budgetqouterequest, pumpcapacity, jockeypump, controllermanufacturer, controllermodel, guagequantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("pumptype", pumptype)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("pumpcapacity", pumpcapacity)
    formData.append("jockeypump", jockeypump)
    formData.append("controllermanufacturer", controllermanufacturer)
    formData.append("controllermodel", controllermodel)
    formData.append("guagequantity", guagequantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addfirepump', formData);
    // console.log('response after api hit ->', response.data)
    const { firepump, error, message } = response.data;
    localStorage.setItem('firepumpID', firepump.id);
    dispatch({
      type: 'COMPANYFIREPUMP',
      payload: {
        firepump,
        message,
        error
      },
    });
  };
  const updatefirepumpregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, pumptype, replacementavailable, budgetqouterequest, pumpcapacity, jockeypump, controllermanufacturer, controllermodel, guagequantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("pumptype", pumptype)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("pumpcapacity", pumpcapacity)
    formData.append("jockeypump", jockeypump)
    formData.append("controllermanufacturer", controllermanufacturer)
    formData.append("controllermodel", controllermodel)
    formData.append("guagequantity", guagequantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatefirepump/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { firepump, error, message } = response.data;
    localStorage.setItem('firepumpID', firepump.id);
    dispatch({
      type: 'COMPANYFIREPUMP',
      payload: {
        firepump,
        message,
        error
      },
    });
  };
  const preactionregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementavailable, budgetqouterequest, hydraulicallydesigned, nextdate, aircompressorid, aircompressormake, aircompressormodel, aircompressorcapacity, aircompressorhorsepower, aircompressorpowersupply, aircompressortank, maintenancedevice, maintenancedevicemake, maintenancedevicemodel, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("hydraulicallydesigned", hydraulicallydesigned)
    formData.append("nextdate", nextdate)
    formData.append("aircompressorid", aircompressorid)
    formData.append("aircompressormake", aircompressormake)
    formData.append("aircompressormodel", aircompressormodel)
    formData.append("aircompressorcapacity", aircompressorcapacity)
    formData.append("aircompressorhorsepower", aircompressorhorsepower)
    formData.append("aircompressorpowersupply", aircompressorpowersupply)
    formData.append("aircompressortank", aircompressortank)
    formData.append("maintenancedevice", maintenancedevice)
    formData.append("maintenancedevicemake", maintenancedevicemake)
    formData.append("maintenancedevicemodel", maintenancedevicemodel)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addpreaction', formData);
    // console.log('response after api hit ->', response.data)
    const { preaction, error, message } = response.data;
    localStorage.setItem('preactionID', preaction.id);
    dispatch({
      type: 'COMPANYPREACTION',
      payload: {
        preaction,
        message,
        error
      },
    });
  };

  const updatepreaction = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementavailable, budgetqouterequest, hydraulicallydesigned, nextdate, aircompressorid, aircompressormake, aircompressormodel, aircompressorcapacity, aircompressorhorsepower, aircompressorpowersupply, aircompressortank, maintenancedevice, maintenancedevicemake, maintenancedevicemodel, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("hydraulicallydesigned", hydraulicallydesigned)
    formData.append("nextdate", nextdate)
    formData.append("aircompressorid", aircompressorid)
    formData.append("aircompressormake", aircompressormake)
    formData.append("aircompressormodel", aircompressormodel)
    formData.append("aircompressorcapacity", aircompressorcapacity)
    formData.append("aircompressorhorsepower", aircompressorhorsepower)
    formData.append("aircompressorpowersupply", aircompressorpowersupply)
    formData.append("aircompressortank", aircompressortank)
    formData.append("maintenancedevice", maintenancedevice)
    formData.append("maintenancedevicemake", maintenancedevicemake)
    formData.append("maintenancedevicemodel", maintenancedevicemodel)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatepreaction/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { preaction, error, message } = response.data;
    localStorage.setItem('preactionID', preaction.id);
    dispatch({
      type: 'COMPANYPREACTION',
      payload: {
        preaction,
        message,
        error
      },
    });
  };

  const delugeregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementavailable, budgetqouterequest, hydraulicallydesigned, nextdate, aircompressorid, aircompressormake, aircompressormodel, aircompressorcapacity, aircompressorhorsepower, aircompressorpowersupply, aircompressortank, maintenancedevice, maintenancedevicemake, maintenancedevicemodel, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("hydraulicallydesigned", hydraulicallydesigned)
    formData.append("nextdate", nextdate)
    formData.append("aircompressorid", aircompressorid)
    formData.append("aircompressormake", aircompressormake)
    formData.append("aircompressormodel", aircompressormodel)
    formData.append("aircompressorcapacity", aircompressorcapacity)
    formData.append("aircompressorhorsepower", aircompressorhorsepower)
    formData.append("aircompressorpowersupply", aircompressorpowersupply)
    formData.append("aircompressortank", aircompressortank)
    formData.append("maintenancedevice", maintenancedevice)
    formData.append("maintenancedevicemake", maintenancedevicemake)
    formData.append("maintenancedevicemodel", maintenancedevicemodel)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/adddeluge', formData);
    // console.log('response after api hit ->', response.data)
    const { deluge, error, message } = response.data;
    localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'COMPANYDELUGE',
      payload: {
        deluge,
        message,
        error
      },
    });
  };
  const updatedelugeregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, typeofpipe, replacementavailable, budgetqouterequest, hydraulicallydesigned, nextdate, aircompressorid, aircompressormake, aircompressormodel, aircompressorcapacity, aircompressorhorsepower, aircompressorpowersupply, aircompressortank, maintenancedevice, maintenancedevicemake, maintenancedevicemodel, sprinklerheads, guagequantity, drainquantity, isolationvalvequantity, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("typeofpipe", typeofpipe)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("hydraulicallydesigned", hydraulicallydesigned)
    formData.append("nextdate", nextdate)
    formData.append("aircompressorid", aircompressorid)
    formData.append("aircompressormake", aircompressormake)
    formData.append("aircompressormodel", aircompressormodel)
    formData.append("aircompressorcapacity", aircompressorcapacity)
    formData.append("aircompressorhorsepower", aircompressorhorsepower)
    formData.append("aircompressorpowersupply", aircompressorpowersupply)
    formData.append("aircompressortank", aircompressortank)
    formData.append("maintenancedevice", maintenancedevice)
    formData.append("maintenancedevicemake", maintenancedevicemake)
    formData.append("maintenancedevicemodel", maintenancedevicemodel)
    formData.append("sprinklerheads", sprinklerheads)
    formData.append("guagequantity", guagequantity)
    formData.append("drainquantity", drainquantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatedeluge/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { deluge, error, message } = response.data;
    localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'COMPANYDELUGE',
      payload: {
        deluge,
        message,
        error
      },
    });
  };
  // Turabi Isolation VALVE CODE

  const delugeisolationadd = async (customerid, type, size, takeout, valvephoto) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    formData.append("valvephoto", valvephoto)
    const response = await axios.post('api/customer/adddelugeisolationvalve', formData);
    console.log('response isoloation ->', response)
    const { delugeisolation, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELUGEISOLATIONFORM',
      payload: {
        delugeisolation,
        message,
        error
      },
    });
  };
  const delugeisolationupdate = async (id, customerid, type, size, takeout, valvephoto) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    formData.append("valvephoto", valvephoto)
    const response = await axios.post(`api/customer/updatedelugeisolationvalve/${id}`, formData);
    console.log('response isoloation ->', response)
    const { delugeisolation, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELUGEISOLATIONFORM',
      payload: {
        delugeisolation,
        message,
        error
      },
    });
  };
  const deleugehydraulicadd = async (customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('pipeschedule', pipeschedule)
    formData.append('location', location)
    formData.append('discharge', discharge)
    formData.append('density', density)
    formData.append('flowpressure', flowpressure)
    formData.append('residualpressure', residualpressure)
    formData.append('occupancyclassification', occupancyclassification)
    formData.append('maxstorageheight', maxstorageheight)
    formData.append('hosestreamallowance', hosestreamallowance)

    const response = await axios.post('api/customer/adddelugehydraulicnameplate', formData);
    console.log('response isoloation ->', response)
    const { delugenameplate, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHYDRAULICADD',
      payload: {
        delugenameplate,
        message,
        error
      },
    });
  }
  const deleugehydraulicupdate = async (id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('pipeschedule', pipeschedule)
    formData.append('location', location)
    formData.append('discharge', discharge)
    formData.append('density', density)
    formData.append('flowpressure', flowpressure)
    formData.append('residualpressure', residualpressure)
    formData.append('occupancyclassification', occupancyclassification)
    formData.append('maxstorageheight', maxstorageheight)
    formData.append('hosestreamallowance', hosestreamallowance)

    const response = await axios.post(`api/customer/updatedelugehydraulicnameplate/${id}`, formData);
    console.log('response isoloation ->', response)
    const { delugenameplate, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHYDRAULICADD',
      payload: {
        delugenameplate,
        message,
        error
      },
    });
  }
  const delugeheadadd = async (
    customerid,
    type,
    location,
    temperature,
    triggertype,
    installdate,
    servicedate,
    nextservicedate) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('location', location)
    formData.append('temperature', temperature)
    formData.append('triggertype', triggertype)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post('api/customer/adddelugehead', formData);
    console.log('response isoloation ->', response)
    const { delugehead, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHEAD',
      payload: {
        delugehead,
        message,
        error
      },
    });
  }
  const delugeheadupdate = async (
    id,
    customerid,
    type,
    location,
    temperature,
    triggertype,
    installdate,
    servicedate,
    nextservicedate) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('location', location)
    formData.append('temperature', temperature)
    formData.append('triggertype', triggertype)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post(`api/customer/updatedelugehead/${id}`, formData);
    console.log('response isoloation ->', response)
    const { delugehead, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEHEAD',
      payload: {
        delugehead,
        message,
        error
      },
    });
  }
  const delugeguageadd = async (
    customerid,
    guageid,
    location,
    type,
    installdate,
    servicedate,
    nextservicedate) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('guageid', guageid)
    formData.append('location', location)
    formData.append('type', type)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post('api/customer/adddelugeguage', formData);
    console.log('response isoloation ->', response)
    const { delugeguage, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEGUAGE',
      payload: {
        delugeguage,
        message,
        error
      },
    });
  }
  const delugeguageupdate = async (
    id,
    customerid,
    guageid,
    location,
    type,
    installdate,
    servicedate,
    nextservicedate,
  ) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('guageid', guageid)
    formData.append('location', location)
    formData.append('installdate', installdate)
    formData.append('servicedate', servicedate)
    formData.append('nextservicedate', nextservicedate)

    const response = await axios.post(`api/customer/updatedelugeguage/${id}`, formData);
    console.log('response isoloation ->', response)
    const { delugeguage, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEGUAGE',
      payload: {
        delugeguage,
        message,
        error
      },
    });
  }
  const delugedrainadd = async (
    customerid,
    type,
    size,
    location,
    connection,
    notes

  ) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('size', size)
    formData.append('location', location)
    formData.append('connection', connection)
    formData.append('notes', notes)

    const response = await axios.post('api/customer/adddelugedrain', formData);
    console.log('response isoloation ->', response)
    const { delugedrain, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEDRAIN',
      payload: {
        delugedrain,
        message,
        error
      },
    });
  }
  const delugedrainupdate = async (
    id,
    customerid,
    type,
    size,
    location,
    connection,
    notes

  ) => {
    const formData = new FormData();
    formData.append('customerid', customerid)
    formData.append('type', type)
    formData.append('size', size)
    formData.append('location', location)
    formData.append('connection', connection)
    formData.append('notes', notes)

    const response = await axios.post(`api/customer/updatedelugedrain/${id}`, formData);
    console.log('response isoloation ->', response)
    const { delugedrain, error, message } = response.data;
    // localStorage.setItem('delugeID', deluge.id);
    dispatch({
      type: 'DELEUGEDRAIN',
      payload: {
        delugedrain,
        message,
        error
      },
    });
  }
  const standpiperegister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, standpipeclass, standpipetype, standpipefloor, standpipestairwells, guagequantity, isolationvalvequantity, contractorid, servicedate, nextdervicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("standpipeclass", standpipeclass)
    formData.append("standpipetype", standpipetype)
    formData.append("standpipefloor", standpipefloor)
    formData.append("standpipestairwells", standpipestairwells)
    formData.append("guagequantity", guagequantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextdervicedate", nextdervicedate)

    const response = await axios.post('api/customer/addstandpipe', formData);
    // console.log('response after api hit ->', response.data)
    const { standpipe, error, message } = response.data;
    localStorage.setItem('standpipeID', standpipe.id);
    dispatch({
      type: 'COMPANYSTANDPIPE',
      payload: {
        standpipe,
        message,
        error
      },
    });
  };
  const updatestandpiperegister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, standpipeclass, standpipetype, standpipefloor, standpipestairwells, guagequantity, isolationvalvequantity, contractorid, servicedate, nextdervicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("standpipeclass", standpipeclass)
    formData.append("standpipetype", standpipetype)
    formData.append("standpipefloor", standpipefloor)
    formData.append("standpipestairwells", standpipestairwells)
    formData.append("guagequantity", guagequantity)
    formData.append("isolationvalvequantity", isolationvalvequantity)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextdervicedate", nextdervicedate)

    const response = await axios.post(`api/customer/updatestandpipe/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { standpipe, error, message } = response.data;
    localStorage.setItem('standpipeID', standpipe.id);
    dispatch({
      type: 'COMPANYSTANDPIPE',
      payload: {
        standpipe,
        message,
        error
      },
    });
  };
  const firehydrantregister = async (customerid, assetid, firehydrantcount, contractorid) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("firehydrantcount", firehydrantcount)
    formData.append("contractorid", contractorid)
    const response = await axios.post('api/customer/addfirehydrant', formData);
    // console.log('response after api hit ->', response.data)
    const { firehydrant, error, message } = response.data;
    localStorage.setItem('firehydrantID', firehydrant.id);
    dispatch({
      type: 'COMPANYFIREHYDRANT',
      payload: {
        firehydrant,
        message,
        error
      },
    });
  };
  const updatefirehydrantregister = async (id, customerid, assetid, firehydrantcount, contractorid) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("firehydrantcount", firehydrantcount)
    formData.append("contractorid", contractorid)
    const response = await axios.post(`api/customer/updatefirehydrant/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { firehydrant, error, message } = response.data;
    localStorage.setItem('firehydrantID', firehydrant.id);
    dispatch({
      type: 'COMPANYFIREHYDRANT',
      payload: {
        firehydrant,
        message,
        error
      },
    });
  };
  const kitchenhoodregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, ul300, cylindersprovideprotection, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("ul300", ul300)
    formData.append("cylindersprovideprotection", cylindersprovideprotection)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addkitchenhood', formData);
    // console.log('response after api hit ->', response.data)
    const { kitchenhood, error, message } = response.data;
    localStorage.setItem('kitchenhoodID', kitchenhood.id);
    dispatch({
      type: 'COMPANYKITCHENHOOD',
      payload: {
        kitchenhood,
        message,
        error
      },
    });
  };
  const updatekitchenhoodregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, ul300, cylindersprovideprotection, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("ul300", ul300)
    formData.append("cylindersprovideprotection", cylindersprovideprotection)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatekitchenhood/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { kitchenhood, error, message } = response.data;
    localStorage.setItem('kitchenhoodID', kitchenhood.id);
    dispatch({
      type: 'COMPANYKITCHENHOOD',
      payload: {
        kitchenhood,
        message,
        error
      },
    });
  };
  const drychemicalregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, cylindersprovideprotection, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("cylindersprovideprotection", cylindersprovideprotection)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/adddrychemical', formData);
    // console.log('response after api hit ->', response.data)
    const { drychemical, error, message } = response.data;
    localStorage.setItem('drychemicalID', drychemical.id);
    dispatch({
      type: 'COMPANYDRYCHEMICAL',
      payload: {
        drychemical,
        message,
        error
      },
    });
  };
  const updatedrychemicalregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, cylindersprovideprotection, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("cylindersprovideprotection", cylindersprovideprotection)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatedrychemical/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { drychemical, error, message } = response.data;
    localStorage.setItem('drychemicalID', drychemical.id);
    dispatch({
      type: 'COMPANYDRYCHEMICAL',
      payload: {
        drychemical,
        message,
        error
      },
    });
  };
  const fireescaperegister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, escapeprovidecoverage, standpipestairwells, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("escapeprovidecoverage", escapeprovidecoverage)
    formData.append("standpipestairwells", standpipestairwells)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addfireescape ', formData);
    // console.log('response after api hit ->', response.data)
    const { fireescape, error, message } = response.data;
    localStorage.setItem('fireescapeID', fireescape.id);
    dispatch({
      type: 'COMPANYFIREESCAPE',
      payload: {
        fireescape,
        message,
        error
      },
    });
  };
  const updatefireescaperegister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, escapeprovidecoverage, standpipestairwells, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("escapeprovidecoverage", escapeprovidecoverage)
    formData.append("standpipestairwells", standpipestairwells)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatefireescape/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { fireescape, error, message } = response.data;
    localStorage.setItem('fireescapeID', fireescape.id);
    dispatch({
      type: 'COMPANYFIREESCAPE',
      payload: {
        fireescape,
        message,
        error
      },
    });
  };
  const cleanagentregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, cyindersprovideprotection, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("cyindersprovideprotection", cyindersprovideprotection)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addcleanagent', formData);
    // console.log('response after api hit ->', response.data)
    const { cleanagent, error, message } = response.data;
    localStorage.setItem('cleanagentID', cleanagent.id);
    dispatch({
      type: 'COMPANYCLEANAGENT',
      payload: {
        cleanagent,
        message,
        error
      },
    });
  };
  const updatecleanagentregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, cyindersprovideprotection, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("cyindersprovideprotection", cyindersprovideprotection)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updatecleanagent/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { cleanagent, error, message } = response.data;
    localStorage.setItem('cleanagentID', cleanagent.id);
    dispatch({
      type: 'COMPANYCLEANAGENT',
      payload: {
        cleanagent,
        message,
        error
      },
    });
  };
  const otherlocationregister = async (customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, backflowtype, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, replacementavailable, budgetqouterequest, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("backflowtype", backflowtype)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post('api/customer/addother', formData);
    // console.log('response after api hit ->', response.data)
    const { otherlocation, error, message } = response.data;
    localStorage.setItem('otherlocationID', otherlocation.id);
    dispatch({
      type: 'COMPANYOTHERLOCATION',
      payload: {
        otherlocation,
        message,
        error
      },
    });
  };

  const updateotherlocationregister = async (id, customerid, assetid, assetphoto, placardphoto, assetname, assetlocation, assetprotect, assetmake, assetmodel, assetserialnumber, backflowtype, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, replacementavailable, budgetqouterequest, contractorid, servicedate, nextservicedate) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("assetname", assetname)
    formData.append("assetlocation", assetlocation)
    formData.append("assetprotect", assetprotect)
    formData.append("assetmake", assetmake)
    formData.append("assetmodel", assetmodel)
    formData.append("assetserialnumber", assetserialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("backflowtype", backflowtype)
    formData.append("installingcontractor", installingcontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("servicecontractor", servicecontractor)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("replacementavailable", replacementavailable)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("contractorid", contractorid)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)

    const response = await axios.post(`api/customer/updateother/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { otherlocation, error, message } = response.data;
    localStorage.setItem('otherlocationID', otherlocation.id);
    dispatch({
      type: 'COMPANYOTHERLOCATION',
      payload: {
        otherlocation,
        message,
        error
      },
    });
  };

  const companybatterydetail = async (customerid, onsiteid, location, type, voltage, amperage, installdate, servicedate, nextservicedate, notes) => {
    const response = await axios.post('api/customer/addfirealarmbatterydetail', {
      customerid,
      onsiteid,
      location,
      type,
      voltage,
      amperage,
      installdate,
      servicedate,
      nextservicedate,
      notes
    });
    const { batterydetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('batterydetailID', batterydetail.id);
    dispatch({
      type: 'COMPANYBATTERYDETAIL',
      payload: {
        batterydetail,
        message,
        error
      },
    });
  };

  const companyupdatebatterydetail = async (id, customerid, onsiteid, location, type, voltage, amperage, installdate, servicedate, nextservicedate, notes) => {
    const response = await axios.post(`api/customer/updatefirealarmbatterydetail/${id}`, {
      customerid,
      onsiteid,
      location,
      type,
      voltage,
      amperage,
      installdate,
      servicedate,
      nextservicedate,
      notes
    });
    const { batterydetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    dispatch({
      type: 'COMPANYBATTERYDETAIL',
      payload: {
        batterydetail,
        message,
        error
      },
    });
  };
  const companyductdetector = async (customerid, location, notes) => {
    const response = await axios.post('api/customer/addfirealarmductdetectordetail', {
      customerid,
      location,
      notes
    });
    const { ductdetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('ductdetectorID', ductdetector.id);
    dispatch({
      type: 'COMPANYDUCTDETECTOR',
      payload: {
        ductdetector,
        message,
        error
      },
    });
  };
  const companyupdateductdetector = async (id, customerid, location, notes) => {
    const response = await axios.post(`api/customer/updatefirealarmductdetectordetail/${id}`, {
      customerid,
      location,
      notes
    });
    const { ductdetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('ductdetectorID', ductdetector.id);
    dispatch({
      type: 'COMPANYDUCTDETECTOR',
      payload: {
        ductdetector,
        message,
        error
      },
    });
  };
  const companyheatdetector = async (customerid, range, notes) => {
    const response = await axios.post('api/customer/addfirealarmheatdetectordetail', {
      customerid,
      range,
      notes
    });
    const { heatdetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('heatdetectorID', heatdetector.id);
    dispatch({
      type: 'COMPANYHEATDETECTOR',
      payload: {
        heatdetector,
        message,
        error
      },
    });
  };
  const companyupdateheatdetector = async (id, customerid, range, notes) => {
    const response = await axios.post(`api/customer/updatefirealarmheatdetectordetail/${id}`, {
      customerid,
      range,
      notes
    });
    const { heatdetector, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('heatdetectorID', heatdetector.id);
    dispatch({
      type: 'COMPANYHEATDETECTOR',
      payload: {
        heatdetector,
        message,
        error
      },
    });
  };
  const companysupervisory = async (customerid, type, notes) => {
    const response = await axios.post('api/customer/addfirealarmsupervisoryswitch', {
      customerid,
      type,
      notes
    });
    const { supervisory, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('supervisoryID', supervisory.id);
    dispatch({
      type: 'COMPANYSUPERVISORY',
      payload: {
        supervisory,
        message,
        error
      },
    });
  };
  const companyupdatesupervisory = async (id, customerid, type, notes) => {
    const response = await axios.post(`api/customer/updatefirealarmsupervisoryswitch/${id}`, {
      customerid,
      type,
      notes
    });
    const { supervisory, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('supervisoryID', supervisory.id);
    dispatch({
      type: 'COMPANYSUPERVISORY',
      payload: {
        supervisory,
        message,
        error
      },
    });
  };
  const companyflowswitch = async (customerid, type, size, notes) => {
    const response = await axios.post('api/customer/addfirealarmflowswitch', {
      customerid,
      type,
      size,
      notes
    });
    const { flowswitch, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('flowswitchID', flowswitch.id);
    dispatch({
      type: 'COMPANYFLOWSWITCH',
      payload: {
        flowswitch,
        message,
        error
      },
    });
  };
  const companyupdateflowswitch = async (id, customerid, type, size, notes) => {
    const response = await axios.post(`api/customer/updatefirealarmflowswitch/${id}`, {
      customerid,
      type,
      size,
      notes
    });
    const { flowswitch, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('flowswitchID', flowswitch.id);
    dispatch({
      type: 'COMPANYFLOWSWITCH',
      payload: {
        flowswitch,
        message,
        error
      },
    });
  };
  const companywetantifreeze = async (customerid, solutiontype, gallonsquantity, lastdrainmix, ullisted) => {
    const response = await axios.post('api/customer/addwetantifreezesprinkler', {
      customerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { wetantifreeze, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetantifreezeID', wetantifreeze.id);
    dispatch({
      type: 'COMPANYWETANTIFREEZE',
      payload: {
        wetantifreeze,
        message,
        error
      },
    });
  };
  const companyupdatewetantifreeze = async (id, customerid, solutiontype, gallonsquantity, lastdrainmix, ullisted) => {
    const response = await axios.post(`api/customer/updatewetantifreezesprinkler/${id}`, {
      customerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { wetantifreeze, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetantifreezeID', wetantifreeze.id);
    dispatch({
      type: 'COMPANYWETANTIFREEZE',
      payload: {
        wetantifreeze,
        message,
        error
      },
    });
  };
  const companywetguage = async (customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addwetsprinklerguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { wetguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetguageID', wetguage.id);
    dispatch({
      type: 'COMPANYWETGUAGE',
      payload: {
        wetguage,
        message,
        error
      },
    });
  };
  const companyupdatewetguage = async (id, customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatewetsprinklerguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { wetguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetguageID', wetguage.id);
    dispatch({
      type: 'COMPANYWETGUAGE',
      payload: {
        wetguage,
        message,
        error
      },
    });
  };
  const companywetnameplate = async (customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maximumheight, hosestream) => {
    const response = await axios.post('api/customer/addwetsprinklerhydraulicnameplate', {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maximumheight,
      hosestream
    });
    const { wetnameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetnameplateID', wetnameplate.id);
    dispatch({
      type: 'COMPANYNWETAMEPLATE',
      payload: {
        wetnameplate,
        message,
        error
      },
    });
  };
  const companyupdatewetnameplate = async (id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maximumheight, hosestream) => {
    const response = await axios.post(`api/customer/updatewetsprinklerhydraulicnameplate/${id}`, {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maximumheight,
      hosestream
    });
    const { wetnameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetnameplateID', wetnameplate.id);
    dispatch({
      type: 'COMPANYNWETAMEPLATE',
      payload: {
        wetnameplate,
        message,
        error
      },
    });
  };
  const companywetisolation = async (customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addwetsprinklerisolationvalve', formData);
    const { wetisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetisolationID', wetisolation.id);
    dispatch({
      type: 'COMPANYWETISOLATION',
      payload: {
        wetisolation,
        message,
        error
      },
    });
  };
  const companyupdatewetisolation = async (id, customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatewetsprinklerisolationvalve/${id}`, formData);
    const { wetisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetisolationID', wetisolation.id);
    dispatch({
      type: 'COMPANYWETISOLATION',
      payload: {
        wetisolation,
        message,
        error
      },
    });
  };
  const companywetdrain = async (customerid, type, size, location, details, notes) => {
    const response = await axios.post('api/customer/addwetsprinklerdrain', {
      customerid,
      type,
      size,
      location,
      details,
      notes
    });
    const { wetdrain, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetdrainID', wetdrain.id);
    dispatch({
      type: 'COMPANYWETDRAIN',
      payload: {
        wetdrain,
        message,
        error
      },
    });
  };
  const companyupdatewetdrain = async (id, customerid, type, size, location, details, notes) => {
    const response = await axios.post(`api/customer/updatewetsprinklerdrain/${id}`, {
      customerid,
      type,
      size,
      location,
      details,
      notes
    });
    const { wetdrain, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetdrainID', wetdrain.id);
    dispatch({
      type: 'COMPANYWETDRAIN',
      payload: {
        wetdrain,
        message,
        error
      },
    });
  };
  const companywethead = async ({ customerid, installdate, type, location, nextservicedate, servicedate, temperature, triggertype }) => {
    const response = await axios.post('api/customer/addwetsprinklerhead', {
      customerid,
      type,
      installdate,
      location,
      nextservicedate,
      servicedate,
      temperature,
      triggertype
    });
    const { wethead, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetheadID', wethead.id);
    dispatch({
      type: 'COMPANYWETHEAD',
      payload: {
        wethead,
        message,
        error
      },
    });
  };
  const companyupdatewethead = async ({ id, customerid, installdate, type, location, nextservicedate, servicedate, temperature, triggertype }) => {
    const response = await axios.post(`api/customer/updatewetsprinklerhead/${id}`, {
      customerid,
      type,
      installdate,
      location,
      nextservicedate,
      servicedate,
      temperature,
      triggertype
    });
    const { wethead, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('wetheadID', wethead.id);
    dispatch({
      type: 'COMPANYWETHEAD',
      payload: {
        wethead,
        message,
        error
      },
    });
  };
  const companydryantifreeze = async (customerid, solutiontype, gallonsquantity, lastdrainmix, ullisted) => {
    const response = await axios.post('api/customer/adddryantifreezesprinkler', {
      customerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { dryantifreeze, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryantifreezeID', dryantifreeze.id);
    dispatch({
      type: 'COMPANYDRYANTIFREEZE',
      payload: {
        dryantifreeze,
        message,
        error
      },
    });
  };
  const companydryguage = async (customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/adddrysprinklerguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryguageID', dryguage.id);
    dispatch({
      type: 'COMPANYDRYGUAGE',
      payload: {
        dryguage,
        message,
        error
      },
    });
  };
  const companyupdatedryguage = async (id, customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatedrysprinklerguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryguageID', dryguage.id);
    dispatch({
      type: 'COMPANYDRYGUAGE',
      payload: {
        dryguage,
        message,
        error
      },
    });
  };
  const companydrynameplate = async (customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const response = await axios.post('api/customer/adddrysprinklerhydraulicnameplate', {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { drynameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('drynameplateID', drynameplate.id);
    dispatch({
      type: 'COMPANYDRYNAMEPLATE',
      payload: {
        drynameplate,
        message,
        error
      },
    });
  };
  const companyupdatedrynameplate = async (id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const response = await axios.post(`api/customer/updatedrysprinklerhydraulicnameplate/${id}`, {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { drynameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('drynameplateID', drynameplate.id);
    dispatch({
      type: 'COMPANYDRYNAMEPLATE',
      payload: {
        drynameplate,
        message,
        error
      },
    });
  };
  const companydryisolation = async (customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/adddrysprinklerisolationvalve', formData);
    const { dryisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryisolationID', dryisolation.id);
    dispatch({
      type: 'COMPANYDRYISOLATION',
      payload: {
        dryisolation,
        message,
        error
      },
    });
  };

  const companyupdatedryisolation = async (id, customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatedrysprinklerisolationvalve/${id}`, formData);
    const { dryisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryisolationID', dryisolation.id);
    dispatch({
      type: 'COMPANYDRYISOLATION',
      payload: {
        dryisolation,
        message,
        error
      },
    });
  };
  const companydrydrain = async (customerid, type, size, location, connection, notes) => {
    const response = await axios.post('api/customer/adddrysprinklerdrain', {
      customerid,
      type,
      size,
      location,
      connection,
      notes
    });
    const { drydrain, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('drydrainID', drydrain.id);
    dispatch({
      type: 'COMPANYDRYDRAIN',
      payload: {
        drydrain,
        message,
        error
      },
    });
  };
  const companyupdatedrydrain = async (id, customerid, type, size, location, connection, notes) => {
    const response = await axios.post(`api/customer/updatedrysprinklerdrain/${id}`, {
      customerid,
      type,
      size,
      location,
      connection,
      notes
    });
    const { drydrain, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('drydrainID', drydrain.id);
    dispatch({
      type: 'COMPANYDRYDRAIN',
      payload: {
        drydrain,
        message,
        error
      },
    });
  };
  const companydryhead = async (customerid, type, location, temperature, triggertype, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/adddrysprinklerhead', {
      customerid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryhead, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryheadID', dryhead.id);
    dispatch({
      type: 'COMPANYDRYHEAD',
      payload: {
        dryhead,
        message,
        error
      },
    });
  };
  const companyupdatedryhead = async (id, customerid, type, location, temperature, triggertype, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatedrysprinklerhead/${id}`, {
      customerid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      servicedate,
      nextservicedate
    });
    const { dryhead, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('dryheadID', dryhead.id);
    dispatch({
      type: 'COMPANYDRYHEAD',
      payload: {
        dryhead,
        message,
        error
      },
    });
  };
  const companypreantifreeze = async (customerid, solutiontype, gallonsquantity, lastdrainmix, ullisted) => {
    const response = await axios.post('api/customer/addpreantifreezesprinkler', {
      customerid,
      solutiontype,
      gallonsquantity,
      lastdrainmix,
      ullisted
    });
    const { preantifreeze, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preantifreezeID', preantifreeze.id);
    dispatch({
      type: 'COMPANYPREANTIFREEZE',
      payload: {
        preantifreeze,
        message,
        error
      },
    });
  };
  const companypreguage = async (customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addpreactionguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { preguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preguageID', preguage.id);
    dispatch({
      type: 'COMPANYPREGUAGE',
      payload: {
        preguage,
        message,
        error
      },
    });
  };
  const companyupdatepreguage = async (id, customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatepreactionguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { preguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preguageID', preguage.id);
    dispatch({
      type: 'COMPANYPREGUAGE',
      payload: {
        preguage,
        message,
        error
      },
    });
  };
  const companyprenameplate = async (customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const response = await axios.post('api/customer/addpreactionhydraulicnameplate', {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { prenameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('prenameplateID', prenameplate.id);
    dispatch({
      type: 'COMPANYNPREAMEPLATE',
      payload: {
        prenameplate,
        message,
        error
      },
    });
  };
  const companyupdateprenameplate = async (id, customerid, pipeschedule, location, discharge, density, flowpressure, residualpressure, occupancyclassification, maxstorageheight, hosestreamallowance) => {
    const response = await axios.post(`api/customer/updatepreactionhydraulicnameplate/${id}`, {
      customerid,
      pipeschedule,
      location,
      discharge,
      density,
      flowpressure,
      residualpressure,
      occupancyclassification,
      maxstorageheight,
      hosestreamallowance
    });
    const { prenameplate, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('prenameplateID', prenameplate.id);
    dispatch({
      type: 'COMPANYNPREAMEPLATE',
      payload: {
        prenameplate,
        message,
        error
      },
    });
  };
  const companypreisolation = async (customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addpreactionisolationvalve', formData);
    const { preisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preisolationID', preisolation.id);
    dispatch({
      type: 'COMPANYPREISOLATION',
      payload: {
        preisolation,
        message,
        error
      },
    });
  };
  const companyupdatepreisolation = async (id, customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatepreactionisolationvalve/${id}`, formData);
    const { preisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preisolationID', preisolation.id);
    dispatch({
      type: 'COMPANYPREISOLATION',
      payload: {
        preisolation,
        message,
        error
      },
    });
  };
  const companypredrain = async (customerid, type, size, location, connection, notes) => {
    const response = await axios.post('api/customer/addpreactiondrain', {
      customerid,
      type,
      size,
      location,
      connection,
      notes
    });
    const { predrain, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('predrainID', predrain.id);
    dispatch({
      type: 'COMPANYPREDRAIN',
      payload: {
        predrain,
        message,
        error
      },
    });
  };
  const companyupdatepredrain = async (id, customerid, type, size, location, connection, notes) => {
    const response = await axios.post(`api/customer/updatepreactiondrain/${id}`, {
      customerid,
      type,
      size,
      location,
      connection,
      notes
    });
    const { predrain, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('predrainID', predrain.id);
    dispatch({
      type: 'COMPANYPREDRAIN',
      payload: {
        predrain,
        message,
        error
      },
    });
  };
  const companyprehead = async (customerid, type, location, temperature, triggertype, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addpreactionhead', {
      customerid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      nextservicedate,
      servicedate,
    });
    const { prehead, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preheadID', prehead.id);
    dispatch({
      type: 'COMPANYPREHEAD',
      payload: {
        prehead,
        message,
        error
      },
    });
  };
  const companyupdateprehead = async (id, customerid, type, location, temperature, triggertype, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatepreactionhead/${id}`, {
      customerid,
      type,
      location,
      temperature,
      triggertype,
      installdate,
      nextservicedate,
      servicedate,
    });
    const { prehead, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('preheadID', prehead.id);
    dispatch({
      type: 'COMPANYPREHEAD',
      payload: {
        prehead,
        message,
        error
      },
    });
  };
  const companykhcylinderdetail = async (customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addkitchenhoodcylinderdetail', {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { khcylinderdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('khcylinderdetailID', khcylinderdetail.id);
    dispatch({
      type: 'COMPANYKHCYLINDERDETAIL',
      payload: {
        khcylinderdetail,
        message,
        error
      },
    });
  };
  const companyupdatekhcylinderdetail = async (id, customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatekitchenhoodcylinderdetail/${id}`, {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { khcylinderdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('khcylinderdetailID', khcylinderdetail.id);
    dispatch({
      type: 'COMPANYKHCYLINDERDETAIL',
      payload: {
        khcylinderdetail,
        message,
        error
      },
    });
  };

  const companycylinderdetail = async (customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/adddrychemicalcylinderdetail', {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cylinderdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('cylinderdetailID', cylinderdetail.id);
    dispatch({
      type: 'COMPANYCYLINDERDETAIL',
      payload: {
        cylinderdetail,
        message,
        error
      },
    });
  };
  const companyupdatecylinderdetail = async (id, customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatedrychemicalcylinderdetail/${id}`, {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cylinderdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('cylinderdetailID', cylinderdetail.id);
    dispatch({
      type: 'COMPANYCYLINDERDETAIL',
      payload: {
        cylinderdetail,
        message,
        error
      },
    });
  };


  const companycacylinderdetail = async (customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addcleanagentcylinderdetail', {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cacylinderdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('cacylinderdetailID', cacylinderdetail.id);
    dispatch({
      type: 'COMPANYCACYLINDERDETAIL',
      payload: {
        cacylinderdetail,
        message,
        error
      },
    });
  };
  const companyupdatecacylinderdetail = async (id, customerid, cylinderid, location, type, make, cylinderborndate, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatecleanagentcylinderdetail/${id}`, {
      customerid,
      cylinderid,
      location,
      type,
      make,
      cylinderborndate,
      installdate,
      servicedate,
      nextservicedate,
    });
    const { cacylinderdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('cacylinderdetailID', cacylinderdetail.id);
    dispatch({
      type: 'COMPANYCACYLINDERDETAIL',
      payload: {
        cacylinderdetail,
        message,
        error
      },
    });
  };


  const companyfhdetail = async (customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, valvelocated, servicedate, nextservicedate) => {
    console.log("photo", assetphoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("valvelocated", valvelocated)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post('api/customer/addfirehydrantdetail', formData);
    const { fhdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fhdetailID', fhdetail.id);
    dispatch({
      type: 'COMPANYFHDETAIL',
      payload: {
        fhdetail,
        message,
        error
      },
    });
  };
  const companyupdatefhdetail = async (id, customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, valvelocated, servicedate, nextservicedate) => {
    console.log("photo", assetphoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("valvelocated", valvelocated)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post(`api/customer/updatefirehydrantdetail/${id}`, formData);
    const { fhdetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fhdetailID', fhdetail.id);
    dispatch({
      type: 'COMPANYFHDETAIL',
      payload: {
        fhdetail,
        message,
        error
      },
    });
  };
  const companyfedetail = async (customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, servicedate, nextservicedate) => {
    console.log("photo", assetphoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post('api/customer/addfireextingusherdetail', formData);
    const { fedetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fedetailID', fedetail.id);
    dispatch({
      type: 'COMPANYFEDETAIL',
      payload: {
        fedetail,
        message,
        error
      },
    });
  };
  const companyupdatefedetail = async (id, customerid, assetphoto, placardphoto, name, location, protect, make, model, serialnumber, assetborndate, installingcontractor, servicecontractor, distributorcompany, assetahj, assetcondition, budgetqouterequest, servicedate, nextservicedate) => {
    console.log("photo", assetphoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetphoto", assetphoto)
    formData.append("placardphoto", placardphoto)
    formData.append("name", name)
    formData.append("location", location)
    formData.append("protect", protect)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("serialnumber", serialnumber)
    formData.append("assetborndate", assetborndate)
    formData.append("installingcontractor", installingcontractor)
    formData.append("servicecontractor", servicecontractor)
    formData.append("distributorcompany", distributorcompany)
    formData.append("assetahj", assetahj)
    formData.append("assetcondition", assetcondition)
    formData.append("budgetqouterequest", budgetqouterequest)
    formData.append("servicedate", servicedate)
    formData.append("nextservicedate", nextservicedate)
    // return
    const response = await axios.post(`api/customer/updatefireextingusherdetail/${id}`, formData);
    const { fedetail, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fedetailID', fedetail.id);
    dispatch({
      type: 'COMPANYFEDETAIL',
      payload: {
        fedetail,
        message,
        error
      },
    });
  };
  const companybfisolation = async (customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addbackflowisolationvalve', formData);
    const { bfisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('bfisolationID', bfisolation.id);
    dispatch({
      type: 'COMPANYBFISOLATION',
      payload: {
        bfisolation,
        message,
        error
      },
    });
  };
  const companyupdatebfisolation = async (id, customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatebackflowisolationvalve/${id}`, formData);
    const { bfisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('bfisolationID', bfisolation.id);
    dispatch({
      type: 'COMPANYBFISOLATION',
      payload: {
        bfisolation,
        message,
        error
      },
    });
  };
  const companyspguage = async (customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addstandpipeguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { spguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('spguageID', spguage.id);
    dispatch({
      type: 'COMPANYSTANDGUAGE',
      payload: {
        spguage,
        message,
        error
      },
    });
  };
  const companyupdatespguage = async (id, customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatestandpipeguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { spguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('spguageID', spguage.id);
    dispatch({
      type: 'COMPANYSTANDGUAGE',
      payload: {
        spguage,
        message,
        error
      },
    });
  };
  const companyspisolation = async (customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addstandpipeisolationvalve', formData);
    const { spisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('spisolationID', spisolation.id);
    dispatch({
      type: 'COMPANYSTANDISOLATION',
      payload: {
        spisolation,
        message,
        error
      },
    });
  };
  const companyupdatespisolation = async (id, customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatestandpipeisolationvalve/${id}`, formData);
    const { spisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('spisolationID', spisolation.id);
    dispatch({
      type: 'COMPANYSTANDISOLATION',
      payload: {
        spisolation,
        message,
        error
      },
    });
  };
  const companyfpguage = async (customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post('api/customer/addfirepumpguage', {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { fpguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fpguageID', fpguage.id);
    dispatch({
      type: 'COMPANYFPGUAGE',
      payload: {
        fpguage,
        message,
        error
      },
    });
  };
  const companyupdatefpguage = async (id, customerid, guageid, location, type, installdate, servicedate, nextservicedate) => {
    const response = await axios.post(`api/customer/updatefirepumpguage/${id}`, {
      customerid,
      guageid,
      location,
      type,
      installdate,
      servicedate,
      nextservicedate
    });
    const { fpguage, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fpguageID', fpguage.id);
    dispatch({
      type: 'COMPANYFPGUAGE',
      payload: {
        fpguage,
        message,
        error
      },
    });
  };
  const companyfpisolation = async (customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post('api/customer/addfirepumpisolationvalve', formData);
    const { fpisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fpisolationID', fpisolation.id);
    dispatch({
      type: 'COMPANYFPISOLATION',
      payload: {
        fpisolation,
        message,
        error
      },
    });
  };
  const companyupdatefpisolation = async (id, customerid, valvephoto, type, size, takeout) => {
    console.log("photo", valvephoto);
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("valvephoto", valvephoto)
    formData.append("type", type)
    formData.append("size", size)
    formData.append("takeout", takeout)
    // return
    const response = await axios.post(`api/customer/updatefirepumpisolationvalve/${id}`, formData);
    const { fpisolation, error, message } = response.data;
    // console.log('response after api hit ->',)
    localStorage.setItem('fpisolationID', fpisolation.id);
    dispatch({
      type: 'COMPANYFPISOLATION',
      payload: {
        fpisolation,
        message,
        error
      },
    });
  };
  const customercompany = async (id) => {
    const response = await axios.get(`api/customer/companies/${id}`);
    const { company, contact, location, service, jobentery, elevator, keylogs, firedep, firealarm, drysprinkler, wetsprinkler, firepump, preaction, deluge, standpipe, firehydrant, kitchenhood, cleanagent, drychemical, fireescape, fireextinguisher, backflow, otherlocation, batterydetail, ductdetector, heatdetector, supervisory, flowswitch, wetantifreeze, wetguage, wetnameplate, wetisolation, wetdrain, wethead, dryantifreeze, dryguage, drynameplate, dryisolation, drydrain, dryhead, delugenameplate, delugeisolation, delugehead, delugeguage, delugedrain, preantifreeze, preguage, prenameplate, preisolation, predrain, prehead, spguage, spisolation, fpguage, fpisolation, khcylinderdetail, cacylinderdetail, cylinderdetail, fhdetail, fedetail, bfisolation } = response.data;
    // console.data(response.data)
    dispatch({
      type: 'COMPANYDATA',
      payload: {
        company,
        contact,
        location,
        service,
        jobentery,
        elevator,
        keylogs,
        firedep,
        firealarm,
        drysprinkler,
        wetsprinkler,
        firepump,
        preaction,
        deluge,
        standpipe,
        firehydrant,
        kitchenhood,
        cleanagent,
        drychemical,
        fireescape,
        fireextinguisher,
        backflow,
        otherlocation,
        batterydetail,
        ductdetector,
        heatdetector,
        supervisory,
        flowswitch,
        wetguage,
        wetantifreeze,
        wetnameplate,
        wetisolation,
        wetdrain,
        wethead,
        dryantifreeze,
        dryguage,

        // Turabi

        drynameplate,
        dryisolation,
        delugehead,
        delugeguage,
        delugedrain,
        drydrain,
        dryhead,
        delugenameplate,
        delugeisolation,
        preantifreeze,
        preguage,
        prenameplate,
        preisolation,
        predrain,
        prehead,
        spguage,
        spisolation,
        fpguage,
        fpisolation,
        khcylinderdetail,
        cacylinderdetail,
        cylinderdetail,
        fhdetail,
        fedetail,
        bfisolation,
      },
    });
    // 
    dispatch({
      type: 'LoadingCheck',
      payload: {
        locationloading: false
      },
    });
    return response;
  };
  const fireextinguisherregister = async (customerid, assetid, fireextingusherscount, contractorid ) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("fireextingusherscount", fireextingusherscount)
    formData.append("contractorid", contractorid)
    const response = await axios.post('api/customer/addfireextingusher', formData);
    // console.log('response after api hit ->', response.data)
    const { fireextinguisher, error, message } = response.data;
    localStorage.setItem('fireextinguisherID', fireextinguisher.id);
    dispatch({
      type: 'COMPANYFIREEXTINGUISHER',
      payload: {
        fireextinguisher,
        message,
        error
      },
    });
  };

  const updatefireextinguisher = async (id, customerid, assetid, fireextingusherscount, contractorid) => {
    const formData = new FormData();
    formData.append("customerid", customerid)
    formData.append("assetid", assetid)
    formData.append("fireextingusherscount", fireextingusherscount)
    formData.append("contractorid", contractorid)
    const response = await axios.post(`api/customer/updatefireextingusher/${id}`, formData);
    // console.log('response after api hit ->', response.data)
    const { fireextinguisher, error, message } = response.data;
    localStorage.setItem('fireextinguisherID', fireextinguisher.id);
    dispatch({
      type: 'COMPANYFIREEXTINGUISHER',
      payload: {
        fireextinguisher,
        message,
        error
      },
    });
  };
  const GetDocumentData = async (id) => {
    const response = await axios.get(`api/customer/locationuploads/${id}`);
    const { uploads, status } = response.data;
    dispatch({
      type: 'DOCUMENTFORM',
      payload: {
        uploads,
      },
    });
  }
  const getalldefencie = async () => {
    const response = await axios.get(`api/customer/deficiencies`);
    const { deficiencies } = response.data;
    dispatch({
      type: 'DEFICIENCIES',
      payload: {
        deficiencies,
      },
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        // Global Methods Call
        login,
        register,
        logout,
        ChangePath,
        resetpassword,
        verifypassword,
        // Super Admin Methods Call

        // Customer Methods Call
        companyregister,
        companyupdate,
        customercompany,
        companycontact,
        companycontactupdate,
        companylocation,
        companylocationupdate,
        companyservice,
        companyserviceupdate,
        companyjob,
        companyjobupdate,
        companyelevator,
        companyelevatorupdate,
        // Turabi
        delugeisolationadd,
        delugeisolationupdate,
        deleugehydraulicadd,
        deleugehydraulicupdate,
        delugeheadadd,
        delugeheadupdate,
        delugeguageadd,
        delugeguageupdate,
        delugedrainadd,
        delugedrainupdate,
        companykeylogs,
        companyfiredep,
        updatecompanyfiredep,
        firealarmregister,
        updatefirealarmregister,
        drysprinklerregister,
        updatedrysprinklerregister,
        wetsprinklerregister,
        updatewetsprinklerregister,
        firepumpregister,
        updatefirepumpregister,
        preactionregister,
        updatepreaction,
        delugeregister,
        updatedelugeregister,
        standpiperegister,
        updatestandpiperegister,
        firehydrantregister,
        updatefirehydrantregister,
        kitchenhoodregister,
        updatekitchenhoodregister,
        cleanagentregister,
        updatecleanagentregister,
        drychemicalregister,
        updatedrychemicalregister,
        fireescaperegister,
        updatefireescaperegister,
        fireextinguisherregister,
        updatefireextinguisher,
        backflowregister,
        updatebackflowregister,
        otherlocationregister,
        updateotherlocationregister,
        companybatterydetail,
        companyupdatebatterydetail,
        companyductdetector,
        companyupdateductdetector,
        companyheatdetector,
        companyupdateheatdetector,
        companyflowswitch,
        companyupdateflowswitch,
        companysupervisory,
        companyupdatesupervisory,
        companywetantifreeze,
        companyupdatewetantifreeze,
        companywetguage,
        companyupdatewetguage,
        companywetnameplate,
        companyupdatewetnameplate,
        companywetisolation,
        companyupdatewetisolation,
        companywetdrain,
        companyupdatewetdrain,
        companywethead,
        companyupdatewethead,
        companydryantifreeze,
        companydryguage,
        companyupdatedryguage,
        companydrynameplate,
        companyupdatedrynameplate,
        companydryisolation,
        companyupdatedryisolation,
        companydrydrain,
        companyupdatedrydrain,
        companydryhead,
        companyupdatedryhead,
        companypreantifreeze,
        companypreguage,
        companyupdatepreguage,
        companyprenameplate,
        companyupdateprenameplate,
        companypreisolation,
        companyupdatepreisolation,
        companypredrain,
        companyupdatepredrain,
        companyprehead,
        companyupdateprehead,
        companyspguage,
        companyupdatespguage,
        companyspisolation,
        companyupdatespisolation,
        companyfpguage,
        companyupdatefpguage,
        companyfpisolation,
        companyupdatefpisolation,
        companykhcylinderdetail,
        companyupdatekhcylinderdetail,
        companycacylinderdetail,
        companyupdatecacylinderdetail,
        companycylinderdetail,
        companyupdatecylinderdetail,
        companyfhdetail,
        companyupdatefhdetail,
        companyfedetail,
        companyupdatefedetail,
        companybfisolation,
        companyupdatebfisolation,
        GetDocumentData,
        getalldefencie,
        updatecompanykeylogs,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
