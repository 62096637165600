import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  permissions: [],
  permission: null,
};

const slice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.permission = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getPermissionsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export const getPermission =async (id)=> {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/subadmin/allow/permission/${id}`);
      dispatch(slice.actions.getPermissionsSuccess(response.data.permissionsubadmin));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

// ----------------------------------------------------------------------

